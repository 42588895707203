type TranslateSlug = (i: any) => any;

const finacialReportFilesTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  data.finacialReportFiles &&
    (data.finacialReportFiles.items || []).forEach((element: any) => {
      const slug = element.type.slug;
      const title = element.type.translation.title;
      element?.reports?.forEach((report: any) => {
        const file: any = {
          slug: translateSlug(slug),
          title,
          id: report.url,
          body: `${report.year} ${report.name}`,
        };
        result.push(file);
      });
    });
};

const financialTextContentTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData = [...data.financialType.items, ...data.finacialReportText.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item.id,
      title: item?.page?.translation?.title || item?.type?.translation?.title,
      body: item?.translation?.body,
      slug: (item.slug && translateSlug(item.slug)) || (item?.type?.slug && translateSlug(item?.type?.slug)),
    };
    result.push(data);
  });
};

const collegePrepsTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData = [...data.collegePreps.items, ...data.collegePrepSection.items, ...data.collegePrepType.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item.id,
      title: item?.translation?.title,
      body: item?.translation?.body || '',
      slug: (item.slug && translateSlug(item.slug)) || (item?.type?.slug && translateSlug(item?.type?.slug)),
    };
    result.push(data);
  });
};

const helpCenterTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.helpType.items, ...data.helpSections.items, ...data.helpQA.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item.id,
      title: item?.page?.translation?.title || item?.translation?.title || '',
      body: item?.translation?.body || '',
      slug:
        (item.slug && translateSlug(item.slug)) ||
        (item?.section?.slug && translateSlug(item?.section?.slug)) ||
        (item?.type?.slug && translateSlug(item?.type?.slug)),
    };
    result.push(data);
  });
};

const advisoryTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.advisorySections.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item.id,
      title: item?.translation?.title || '',
      body: item?.translation?.body || '',
      slug: item.slug && translateSlug(item.slug),
    };
    result.push(data);
  });
};

const articleTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.articlesList.items, ...data.articlesDetail.items, ...data.articleType.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item.id || (item.articleId && `${item.articleId}-detail`),
      title: item?.translation?.title || '',
      body: item?.translation?.body || '',
      slug: (item.slug && translateSlug(item.slug)) || (item?.type?.slug && translateSlug(item?.type?.slug)),
    };
    result.push(data);
  });
};

const teamTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.teamsType.items, ...data.teamsTypeDetail.items, ...data.teamMembers.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id || (item?.teamTypeId && `${item?.teamTypeId}-${item?.name}`),
      title:
        item?.translation?.title ||
        item?.page?.translation?.title ||
        (item?.name && item?.surname && `${item?.name} ${item?.surname}`) ||
        '',
      body:
        item?.translation?.body ||
        (item?.translation?.job &&
          `${item.translation.job || ''} ${item.company || ''} ${item.team_bio || ''}`.trim()) ||
        '',
      slug: item.slug && translateSlug(item.slug),
    };
    result.push(data);
  });
};

const testimonialTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [
    ...data.testimonialPageSections.items,
    ...data.testimonialSections.items,
    ...data.testimonialDetails.items,
  ];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id || (item?.sectionId && `${item.sectionId}-${item.name}`),
      slug: (item.slug && translateSlug(item.slug)) || (item?.section?.slug && translateSlug(item.section.slug)),
      title: item?.page?.translation?.title || item?.translation?.title || '',
      body: item?.translation?.body || '',
    };
    result.push(data);
  });
};

const careersTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.hsfCareers.items, ...data.hsfCareersPositions.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id || item?.positionId,
      slug: item?.type?.slug && translateSlug(item.type.slug),
      title:
        item?.type?.translation?.title ||
        (item?.positionId && [item?.translation?.title || '', item?.translation?.subtitle || ''].join(' ').trim()) ||
        '',
      body:
        [item?.translation?.hsf_careers_description || '', item?.translation?.hsf_positions_section_title || '']
          .join(' ')
          .trim() ||
        item?.translation?.body ||
        '',
    };
    result.push(data);
  });
};

const basicPageTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.basicPages.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id,
      slug: item.slug && translateSlug(item.slug),
      title: item?.translation?.title || '',
      body: item?.translation?.body || '',
    };
    result.push(data);
  });
};

const hsfLandingTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.landingTypes.items, ...data.hasfLanding.items, ...data.landingSections.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id,
      slug: (item.slug && translateSlug(item.slug)) || (item?.type?.slug && translateSlug(item?.type?.slug)) || '',
      title: item?.translation?.title || '',
      body: item?.translation?.body || '',
    };
    result.push(data);
  });
};

const scholarshipTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [
    ...data.scholarshipType.items,
    ...data.scholarshipAbout.items,
    ...data.scholarshipSections.items,
    ...data.scholarshipSponsors.items,
  ];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id,
      slug: (item.slug && translateSlug(item.slug)) || (item?.type?.slug && translateSlug(item?.type?.slug)),
      title: item?.translation?.title || '',
      body: [item?.translation?.body || '', item?.translation?.sponsor || ''].join(' ').trim() || '',
    };
    result.push(data);
  });
};

const homeIndexTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [
    ...data.homeHero.items,
    ...data.homeSections.items,
    ...data.homeAdditional.items,
    ...data.homeFeaturesEvent.items,
  ];
  pagesData.forEach((item: any) => {
    const data = {
      id:
        (item?.homeHeroId && `${item?.homeHeroId}-homeHeroId`) ||
        (item?.homeSectionsId && `${item?.homeSectionsId}-homeSectionsId`) ||
        (item?.homeAdditionalId && `${item?.homeAdditionalId}-homeAdditionalId`) ||
        item?.id,
      slug: translateSlug('/'),
      title: [item?.translation?.title || '', item?.translation?.title2 || ''].join(' ').trim() || '',
      body:
        [
          item?.translation?.body || '',
          item?.translation?.data || '',
          item?.translation?.registerBtn || '',
          item?.translation?.learnBtn || '',
        ]
          .join(' ')
          .trim() || '',
    };
    result.push(data);
  });

  if (data?.homePartner) {
    const item = data?.homePartner;
    result.push({
      id: item?.id,
      slug: translateSlug('/'),
      title: item?.title,
      body: item?.body,
    });
  }
};

const hsfStoriesTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.hsfStories.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id,
      slug: item.slug && translateSlug(item.slug),
      title: item?.translation?.title || '',
      body: item?.translation?.body || '',
    };
    result.push(data);
  });
};

const eventsTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.eventPage.items, ...data.events.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id,
      slug: (item.slug && translateSlug(item.slug)) || (item?.type?.slug && translateSlug(item?.type?.slug)),
      title: (item?.location && `${item?.translation?.title} - ${item?.location}`) || item?.translation?.title || '',
      body: item?.translation?.body || '',
    };
    result.push(data);
  });
  const eventsTypes = [...data.eventsTypes.items];
  const eventPageType = [...data.eventPageType.items];
  eventPageType.forEach((item: any) => {
    const parentPage = eventsTypes.find((element: any) => element.directusId === item.page);
    const data = {
      id: item?.id,
      slug: item.slug && translateSlug(item.slug),
      body: item?.translation?.body || '',
      title: parentPage?.translation.title || '',
    };
    result.push(data);
  });
};

const eventCalendarTranslator = (data: any, result: Array<any>, translateSlug: TranslateSlug) => {
  const pagesData: Array<any> = [...data.eventCalendar.items];
  pagesData.forEach((item: any) => {
    const data = {
      id: item?.id,
      slug: item.slug && translateSlug(item.slug),
      title: item?.translation?.tabTitle || '',
      body: item?.translation?.body || '',
    };
    result.push(data);
  });

  pagesData.forEach((item: any) => {
    const data = {
      id: `${item?.id}-calendar`,
      slug: item.slug && translateSlug(item.slug),
      title: item?.translation?.title || '',
      body: item?.translation?.tabTitle || '',
    };
    result.push(data);
  });
};

export const translators = [
  homeIndexTranslator,
  advisoryTranslator,
  articleTranslator,
  basicPageTranslator,
  careersTranslator,
  collegePrepsTranslator,
  eventsTranslator,
  eventCalendarTranslator,
  financialTextContentTranslator,
  finacialReportFilesTranslator,
  helpCenterTranslator,
  hsfLandingTranslator,
  hsfStoriesTranslator,
  scholarshipTranslator,
  teamTranslator,
  testimonialTranslator,
];
