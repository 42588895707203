import React from 'react';
import { ResponsiveChoropleth } from '@nivo/geo';

import usaGeo from './usaGeo.json';

import { Tile, Header, Title, TileContentWrapper, MarginCol } from '../../../../components/common/dashboard-styles';

export default function Map({ config }: any) {
  const renderMap = () => {
    return (
      <ResponsiveChoropleth
        data={config.data}
        features={usaGeo.features}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        colors="nivo"
        domain={[0, 1000000]}
        unknownColor="#666666"
        label="properties.name"
        valueFormat=".2s"
        projectionScale={400}
        projectionTranslation={[0.2, 1.4]}
        projectionRotation={[118, 0, 0]}
        enableGraticule={true}
        graticuleLineColor="#dddddd"
        borderWidth={0.5}
        borderColor="#152538"
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: true,
            translateX: 20,
            itemsSpacing: 0,
            itemWidth: 94,
            itemHeight: 18,
            itemDirection: 'left-to-right',
            itemTextColor: '#444444',
            itemOpacity: 0.85,
            symbolSize: 18,
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000000',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    );
  };

  return (
    <MarginCol span={config.width}>
      <Tile>
        <Header align="middle" justify="space-between">
          <Title>{config.title}</Title>
        </Header>
        <TileContentWrapper>
          <div style={{ height: config.height * 100 }}>{renderMap()}</div>
        </TileContentWrapper>
      </Tile>
    </MarginCol>
  );
}
