import React, { useState } from 'react';

import { useQuery } from '../../../hooks/use-query';
import { HSFStoriesType } from '../../../types/models/hsfstory';
import { getHSFStoriesToPages, IHSFStoryQuery } from '../../../apis/public/hsfstories';

interface IScholarshipFinderContext {
  setFilterQuery?: (queryOptions: IHSFStoryQuery) => void;
  hsfStoryLoading: boolean;
  hsfStoryData?: HSFStoriesType | null;
  queryOptions: IHSFStoryQuery;
  hsfStoriesError?: Error | null;
}

const initialContextValues = {
  hsfStoryLoading: false,
  queryOptions: {},
};

const Context = React.createContext<IScholarshipFinderContext>(initialContextValues);

const HSFStoriesContextProvider: React.FC = ({ children }) => {
  const [filterQueryOptions, setFilterQueryOptions] = useState<IHSFStoryQuery>({ offset: 0 });

  const {
    data: hsfStoryData,
    isLoading: hsfStoryLoading,
    error: hsfStoriesError,
  } = useQuery(
    [getHSFStoriesToPages.QUERY_KEY, filterQueryOptions],
    ({ queryKey }: any) => getHSFStoriesToPages(queryKey[1]),
    {
      initialData: { hsfStories: [], totalCount: 0 },
    }
  );

  const _setFilterQueryOptions = (options: IHSFStoryQuery) => {
    if (!Object.keys(options).length) {
      setFilterQueryOptions(options);
      return;
    }
    setFilterQueryOptions((prevOptions) => ({ ...prevOptions, ...options }));
  };

  return (
    <Context.Provider
      value={{
        hsfStoriesError: hsfStoriesError as any,
        queryOptions: filterQueryOptions,
        hsfStoryData,
        hsfStoryLoading,
        setFilterQuery: _setFilterQueryOptions,
      }}
    >
      {children}
    </Context.Provider>
  );
};

interface IUseHSFStoriesQuery {
  data?: HSFStoriesType | null;
  loading: boolean;
  setFilterQuery: (queryOptions: IHSFStoryQuery) => void;
  queryOptions: IHSFStoryQuery;
  hsfStoriesError?: Error | null;
}

export const useHSFStoriesQuery = (): IUseHSFStoriesQuery => {
  const {
    setFilterQuery: _setFilterQuery,
    hsfStoryData: data,
    hsfStoryLoading: loading,
    queryOptions,
    hsfStoriesError,
  } = React.useContext(Context);

  const setFilterQuery = (options: IHSFStoryQuery) => {
    if (_setFilterQuery) {
      _setFilterQuery(options);
    }
  };

  return { data, loading, setFilterQuery, queryOptions, hsfStoriesError };
};

export default HSFStoriesContextProvider;
