import React from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Modal, Row, Col, Input } from 'antd';

import { remapOptions } from './mentors-filter';

import { Select, FormElementChangeEvent } from '../../../../../../components/forms';
import { RootState } from '../../../../../../state/reducers';
import * as actions from '../../../../../../state/actions/myhsf/mentorship/manual-application/mentors-list/mentors-filter';
import { SelectProps } from '../../../../../../components/forms/select';
import { Button } from '../../../../../../components';
import { LookupType } from '../../../../../../types/models/lookup';
import { useLookupByType } from '../../../../../../state/actions/lookup';
import { getAllLookupsFromState } from '../../../../../../state/selectors/lookup';

const DEFAULT_OPTION = {
  [LookupType.SEX]: { label: 'Sex', value: '' },
  [LookupType.EDUCATION_LEVELS]: { label: 'Education', value: '' },
  [LookupType.EMPLOYMENT_EXPERIENCES]: { label: 'Professional Experience', value: '' },
};
const lookupsNames = [LookupType.SEX, LookupType.EDUCATION_LEVELS, LookupType.EMPLOYMENT_EXPERIENCES];

const AdvancedFilterSelect = styled(Select)`
  margin-bottom: 10px;
`;

const AdvancedFilterInputCss = css({
  marginBottom: '10px',
});

const mapState = ({ manualMentorsMatchFilterState }: RootState) => ({
  locationValue: manualMentorsMatchFilterState.location,
  sexValue: manualMentorsMatchFilterState.sex,
  educationValue: manualMentorsMatchFilterState.education,
  professionalExperience: manualMentorsMatchFilterState.professionalExperience,
});

const mapDispatch = {
  onLocationChange: actions.handleLocationChange,
  onSexChange: actions.handleSexChange,
  onEducationChange: actions.handleEducationChange,
  onProfessionalExperienceChange: actions.handleProfessionalExperienceChange,
};

const connector = connect(mapState, mapDispatch);

type AdvancedFilterReduxProps = ConnectedProps<typeof connector>;

interface AdvancedFilterOwnProps {
  handleApply(): void;
  handleClear(): void;
  handleCancel(): void;
  visible: boolean;
}

const AdvancedFilters: React.FC<AdvancedFilterReduxProps & AdvancedFilterOwnProps> = (props) => {
  const memoryLookups = useSelector(getAllLookupsFromState);
  const { isLoading: isLookupsLoading } = useLookupByType(lookupsNames);
  const onLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onLocationChange(e.target.value);
  };

  const onSexChange = (e: FormElementChangeEvent) => {
    props.onSexChange(e.value);
  };

  const onEducationChange = (e: FormElementChangeEvent) => {
    props.onEducationChange(e.value);
  };

  const onProfessionalExperienceChange = (e: FormElementChangeEvent) => {
    props.onProfessionalExperienceChange(e.value);
  };

  const clearAdvancedFilters = () => {
    props.onLocationChange('');
    props.onSexChange('');
    props.onEducationChange('');
    props.onProfessionalExperienceChange('');

    props.handleClear();
  };

  const getOptions = (lookupName: LookupType.SEX | LookupType.EDUCATION_LEVELS | LookupType.EMPLOYMENT_EXPERIENCES) =>
    memoryLookups[lookupName]
      ? [DEFAULT_OPTION[lookupName], ...memoryLookups[lookupName].map(remapOptions)]
      : [DEFAULT_OPTION[lookupName]];

  const advancedFilterSelectParams: Array<SelectProps> = [
    {
      mode: 'default' as any,
      value: props.sexValue,
      onChange: onSexChange,
      options: getOptions(LookupType.SEX),
      size: 'large',
      showArrow: true,
      loading: isLookupsLoading,
    },
    {
      mode: 'default' as any,
      value: props.educationValue,
      onChange: onEducationChange,
      placeholder: 'Education',
      options: getOptions(LookupType.EDUCATION_LEVELS),
      size: 'large',
      showArrow: true,
      loading: isLookupsLoading,
    },
    {
      mode: 'default',
      value: props.professionalExperience,
      onChange: onProfessionalExperienceChange,
      options: getOptions(LookupType.EMPLOYMENT_EXPERIENCES),
      size: 'large',
      showArrow: true,
      loading: isLookupsLoading,
    },
  ];

  const filterSelects: React.ReactNode = advancedFilterSelectParams.map((params, i) => (
    <Col key={`advanced-select-filter-${i}`} span={24}>
      <AdvancedFilterSelect {...params} />
    </Col>
  ));

  return (
    <Modal
      visible={props.visible}
      title={'More Filters'}
      onCancel={props.handleCancel}
      footer={[
        <Row key="1" justify={'space-between'}>
          <Row>
            <Button key="clear" type="link" onClick={clearAdvancedFilters}>
              Clear Filters
            </Button>
          </Row>
          <Row justify={'space-between'}>
            <Button key="back" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button key="submit" type="primary-blue" onClick={props.handleApply}>
              Apply Filters
            </Button>
          </Row>
        </Row>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Input
            css={AdvancedFilterInputCss}
            size={'large'}
            inputMode={'text'}
            value={props.locationValue}
            onChange={onLocationChange}
            placeholder={'Location'}
          />
        </Col>
        {filterSelects}
      </Row>
    </Modal>
  );
};

export default connector(AdvancedFilters) as React.FC<AdvancedFilterOwnProps>;
