import axios from 'axios';

import { createError } from './error';
import responseParser from './response-parser';

import {
  IEmailConfirmation,
  IForgetPasswordFormData,
  ILogin,
  IResetPasswordFormData,
  ISSOLogin,
  ISignUp,
  IVerificationDetails,
  IEmailUpdateConfirmation,
} from '../types/models/auth';
import { API_URL } from '../constants';
import { getMobileTokenFromLocalStorage } from '../state/reducers/auth';
import { CustomAxiosError } from '../types/models/error';

export async function login(email: string, password: string, rememberMe: boolean): Promise<ILogin> {
  const url = `${API_URL}/users/login`;

  try {
    const response = await axios.post(url, { email, password, rememberMe });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

export async function ssoLogin(
  samlRequest: string,
  email?: string,
  password?: string,
  rememberMe?: boolean
): Promise<ISSOLogin> {
  const url = `${API_URL}/sso/auth`;

  try {
    const response = await axios.post(url, { SAMLRequest: samlRequest, email, password, rememberMe });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

export async function signUp(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  dob: string,
  tos: string,
  role?: string,
  currentClassLevel?: string
): Promise<ISignUp> {
  const url = `${API_URL}/users`;

  try {
    const response = await axios.post(url, {
      firstName,
      lastName,
      email,
      password,
      dob,
      role,
      tos,
      currentClassLevel,
    });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

export const getSignupLink = async (linkId: string) => {
  try {
    const response = await axios.get(`${API_URL}/users/signup-link/${linkId}`);
    return responseParser(response);
  } catch (error) {
    throw createError(error as CustomAxiosError);
  }
};

// sends verification email
export async function sendVerificationEmail({ userId }: ISignUp): Promise<IEmailConfirmation> {
  const url = `${API_URL}/users/${userId}/send-verification-email`;
  try {
    const response = await axios.post(url);
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

// send email for password reset
export async function sendResetPasswordEmail({ email }: IForgetPasswordFormData): Promise<IEmailConfirmation> {
  const url = `${API_URL}/users/send-reset-password-email`;
  try {
    const response = await axios.post(url, { email });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

// verifies the email for the given userId
export async function verifyEmail({ userId, linkId }: IVerificationDetails): Promise<{ emailVerified: string }> {
  const url = `${API_URL}/users/${userId}/verify-email`;
  try {
    const response = await axios.post(url, { linkId });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

// confirms update of user's email address
export async function confirmUpdateEmail({
  userId,
  linkId,
  email,
}: IEmailUpdateConfirmation): Promise<Record<string, unknown>> {
  const url = `${API_URL}/users/${userId}/confirm-update-email`;
  try {
    const response = await axios.post(url, { linkId, email });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

// resets the password for the user
export async function resetPassword(
  { password }: IResetPasswordFormData,
  { userId, linkId }: IVerificationDetails
): Promise<IEmailConfirmation> {
  const url = `${API_URL}/users/${userId}/reset-password`;
  try {
    const response = await axios.post(url, { linkId, password });
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

export async function saveMobileAuthToken(token: string): Promise<void> {
  try {
    const url = `${API_URL}/users/mobile-token?token=${token}`;
    await axios.get(url);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

export async function deleteMobileAuthToken(): Promise<void> {
  try {
    const mobileToken = getMobileTokenFromLocalStorage();
    if (mobileToken?.token) {
      const url = `${API_URL}/users/mobile-token?token=${mobileToken?.token}`;
      await axios.delete(url);
    }
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}
