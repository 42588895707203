/* eslint-disable @typescript-eslint/no-var-requires */
import { camelCase } from 'lodash';

let theme: Record<string, any>;
try {
  const globalTheme = require('../global-theme');
  // Tenant specific theme config
  const tenantConfig = require(`../static/tenant-config`);
  const tenantTheme = tenantConfig?.theme || {};
  theme = { ...globalTheme, ...tenantTheme };
  // convert kebab case to camel case
  theme = Object.keys(theme).reduce((acc: Record<string, any>, key: string) => {
    acc[camelCase(key)] = theme[key];
    return acc;
  }, {});
} catch (err) {
  console.error('Failed to load theme config', err);
  throw err;
}

export default theme;
