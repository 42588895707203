import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';

import { getAuth, isAuthenticated } from '../state/selectors/auth';
import Loader from '../components/common/loader';
import UserAuthContainer from '../containers/auth/user-auth';
import PublicLayout from '../layout/public-layout';
import { navigateTo } from '../state/actions/routing';
import { ssoLoginAction } from '../state/actions/auth';
import { fetchPrivilegesAction } from '../state/actions/privileges';
import { fetchTenantConfigAction } from '../state/actions/tenant';
import { getPrivileges } from '../state/selectors/privilege';
import { getTenant } from '../state/selectors/tenant';
import SSOAuthContainer from '../containers/auth/sso-auth';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function Login({ location }: RouteComponentProps<Record<string, any>>) {
  const { loading } = useSelector(getAuth);
  const { privilegesLoading } = useSelector(getPrivileges);
  const { tenantConfigLoading } = useSelector(getTenant);
  const loggedIn = useSelector(isAuthenticated);
  const dispatch = useDispatch();
  const { pathname } = location || {};

  const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');
  const samlRequest = urlParams.get('SAMLRequest');

  useEffect(() => {
    if (loggedIn && samlRequest) {
      dispatch(ssoLoginAction({ samlRequest }) as any);
      return;
    }

    if (loggedIn) {
      dispatch(navigateTo('/myhsf/dashboard') as any);
      dispatch(fetchPrivilegesAction() as any);
      dispatch(fetchTenantConfigAction() as any);
    }
  }, [loggedIn, pathname, samlRequest, dispatch]);

  if (loading || privilegesLoading || tenantConfigLoading) {
    return <Loader></Loader>;
  }

  return (
    <PublicLayout>
      <Container>
        {!loggedIn && <UserAuthContainer pathname={pathname || ''} samlRequest={samlRequest} />}
        {samlRequest && <SSOAuthContainer />}
      </Container>
    </PublicLayout>
  );
}
