import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from 'react-query';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { Breadcrumb } from 'antd';

import { NavigationWrapper } from '../../../../components/common/page-header';
import { getScholarshipDetail } from '../../../../apis/public/scholarship-finder';
import { ScholarshipDetail } from '../common/scholarship-detail';
import { StyledContainer } from '../common/styles';
import { generateBreadcrumb } from '../common/utils';
import { Alert } from '../../../../components';
import Loader from '../../../../components/common/loader';

type Props = RouteComponentProps<{ id?: string }>;

export const ScholarshipFinderDetail: React.FC<Props> = ({ id }) => {
  const { data, isLoading, error } = useQuery(
    [getScholarshipDetail.QUERY_KEY, { id }],
    ({ queryKey }) => getScholarshipDetail((queryKey[1] as any).id),
    { enabled: !!id }
  );

  const renderContent = () => {
    if (isLoading) {
      return <Loader />;
    }
    if (error) {
      return <Alert type="error" message={(error as any).message} />;
    }

    return (
      <>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(
              { Home: '/' },
              { 'Resources: Scholarship Finder': '/scholarship-finder' },
              { [String(data?.name)]: '' }
            ).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <ScholarshipDetail data={data} />
      </>
    );
  };

  return <StyledContainer>{renderContent()}</StyledContainer>;
};

export default ScholarshipFinderDetail;
