import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby-link';
import { useMutation } from 'react-query';

import { useQuery } from '../../hooks/use-query';
import { sendVerificationEmail, verifyEmail } from '../../apis/auth';
import Loader from '../../components/common/loader';
import EmailVerificationConfirmation from '../../components/user/auth/email-verification-confirmation';
import {
  EMAIL_ALREADY_VERIFIED,
  EMAIL_ALREADY_VERIFIED_URL,
  EMAIL_INVALID_REQUEST,
  EMAIL_LINK_EXPIRED,
  EMAIL_VERIFICATION,
  LOG_IN_URL,
  MESSAGE_STATUS,
} from '../../constants';
import { resetErrorAction } from '../../state/actions/auth';
import { IEmailConfirmation, ISignUp } from '../../types/models/auth';

type VerifyEmailProps = {
  linkId: string;
  userId: string;
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function VerifyEmailContainer({ linkId, userId }: VerifyEmailProps) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<string>('');

  const {
    data: response,
    isLoading,
    error: errorVerification,
  } = useQuery(['', { linkId, userId }], ({ queryKey }) => verifyEmail(queryKey[1] as any), {
    enabled: !isEmpty(linkId) && !isEmpty(userId),
  });

  const handleSignIn = useCallback(() => {
    dispatch(resetErrorAction(LOG_IN_URL));
    navigate(LOG_IN_URL);
  }, [dispatch]);

  const { mutateAsync: sendEmail } = useMutation<IEmailConfirmation, unknown, ISignUp>((formData: ISignUp) =>
    sendVerificationEmail(formData)
  );

  const handleVerificationClick = useCallback(async () => {
    if (userId) {
      try {
        await sendEmail({ userId });
        setStatus(MESSAGE_STATUS.SUCCESS);
      } catch (e) {
        if ((e as any)?.message === EMAIL_ALREADY_VERIFIED.ERROR_MESSAGE) {
          navigate(EMAIL_ALREADY_VERIFIED_URL);
        } else {
          setStatus(MESSAGE_STATUS.ERROR);
        }
      }
    }
  }, [sendEmail, userId]);

  const renderPage = () => {
    if (isLoading) {
      return <Loader />;
    } else if (response && response.emailVerified) {
      return (
        <EmailVerificationConfirmation
          onButtonClick={handleSignIn}
          headingText={EMAIL_VERIFICATION.DISPLAY_MESSAGE}
          buttonText={EMAIL_VERIFICATION.BUTTON_TEXT}
        />
      );
      //TODO: Evaluate against custom error code when backend send it
    } else if ((errorVerification as any)?.message === EMAIL_ALREADY_VERIFIED.ERROR_MESSAGE) {
      return (
        <EmailVerificationConfirmation
          onButtonClick={handleSignIn}
          headingText={EMAIL_ALREADY_VERIFIED.DISPLAY_MESSAGE}
          buttonText={EMAIL_ALREADY_VERIFIED.BUTTON_TEXT}
        />
      );
      //TODO: Evaluate against custom error code when backend send it
    } else if ((errorVerification as any)?.message === EMAIL_LINK_EXPIRED.ERROR_MESSAGE) {
      return (
        <EmailVerificationConfirmation
          onButtonClick={handleVerificationClick}
          headingText={EMAIL_LINK_EXPIRED.DISPLAY_MESSAGE}
          buttonText={EMAIL_LINK_EXPIRED.BUTTON_TEXT}
          status={status}
        />
      );
    } else {
      return (
        <EmailVerificationConfirmation
          onButtonClick={handleVerificationClick}
          headingText={EMAIL_INVALID_REQUEST.DISPLAY_MESSAGE}
          buttonText={EMAIL_INVALID_REQUEST.BUTTON_TEXT}
        />
      );
    }
  };

  return <Container>{renderPage()}</Container>;
}
