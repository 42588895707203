import React, { createContext, useState } from 'react';
import { isEmpty } from 'lodash';
import produce from 'immer';

import { useQuery } from '../../hooks/use-query';
import { getMajorsClassifications, getEthnicities } from '../../apis/public/college-finder';
import { MajorClassification, Ethnicity, CollegeFinderFilters } from '../../types/models/college-finder';

type CollegeFinderContext = {
  majors: MajorClassification[];
  ethnicities: Ethnicity[];
  isLoadingMajors: boolean;
  isLoadingEthnicities: boolean;
  error?: Error | null;
  errorEthnicities?: Error | null;
  filters: CollegeFinderFilters;
  setFilters: (options: CollegeFinderFilters) => void;
  setError?: (error: Error) => void;
};

const initial: CollegeFinderContext = {
  majors: [],
  ethnicities: [],
  isLoadingMajors: true,
  isLoadingEthnicities: true,
  filters: { offset: 0 },
  setFilters: () => undefined,
};

export const CollegeFinderContext = createContext(initial);

const CollegeFinderContextProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<CollegeFinderFilters>(initial.filters);
  const [error, setError] = useState();

  const { data: majors = [], isLoading: isLoadingMajors } = useQuery(
    getMajorsClassifications.QUERY_KEY,
    getMajorsClassifications,
    {
      onError: (error: any) => {
        setError(error);
      },
    }
  );

  const { data: ethnicities = [], isLoading: isLoadingEthnicities } = useQuery(
    getEthnicities.QUERY_KEY,
    getEthnicities,
    {
      onError: (error: any) => {
        setError(error);
      },
    }
  );

  const _setFilters = (options: CollegeFinderFilters) => {
    setFilters((current) => {
      const filters = produce(current, (draft) => {
        if (isEmpty(options)) {
          Object.keys(draft)
            .filter((key) => !['id', 'searchText'].includes(key))
            .forEach((key) => {
              delete draft[key];
            });
        }
      });

      const offset = current.offset != options?.offset ? options?.offset : 0;

      return { ...filters, ...options, offset };
    });
  };

  const _setError = (error: any) => {
    setError(error);
  };

  return (
    <CollegeFinderContext.Provider
      value={{
        majors,
        ethnicities,
        isLoadingMajors,
        isLoadingEthnicities,
        error,
        setError: _setError,
        filters,
        setFilters: _setFilters,
      }}
    >
      {children}
    </CollegeFinderContext.Provider>
  );
};

export default CollegeFinderContextProvider;
