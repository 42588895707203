import styled from '@emotion/styled';
import { Input as $Input, InputNumber as $InputNumber } from 'antd';
import Password from 'antd/lib/input/Password';
import {
  InputProps as $InputProps,
  PasswordProps as $PasswordProps,
  TextAreaProps as $TextAreaProps,
} from 'antd/lib/input';
import React, { Ref, forwardRef, memo, FocusEvent, ChangeEvent } from 'react';
import { isNull } from 'lodash';
import { InputNumberProps as $InputNumberProps } from 'antd/lib/input-number';

import { OnBlurCallback, OnChangeCallback } from '.';

import theme from '../../theme';

export type InputElementProps = {
  inputType?: string;
  onBlur?: OnBlurCallback;
  onChange?: OnChangeCallback;
};

export type TextAreaProps = Omit<$TextAreaProps, 'allowClear' | 'onBlur' | 'onChange'> & InputElementProps;
export type PasswordProps = Omit<$PasswordProps, 'onBlur' | 'onChange'> & InputElementProps;

export type InputNumberProps = Omit<$InputNumberProps, 'type' | 'onBlur' | 'onChange'> & InputElementProps;
export type InputProps = Omit<$InputProps, 'type' | 'allowClear' | 'onBlur' | 'onChange'> & InputElementProps;

const ForwardRefPassword = forwardRef(function InputPassword(
  { name, onBlur, onChange, ...props }: PasswordProps,
  ref: Ref<typeof Password>
) {
  const handleBlur =
    onBlur &&
    ((event: FocusEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      onBlur({ name, value });
    });
  const handleChange =
    onChange &&
    ((event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, selectionStart },
      } = event;
      onChange({
        name,
        value,
        selectionStart: isNull(selectionStart) ? 0 : selectionStart,
      });
    });
  return <$Input.Password {...props} name={name} onBlur={handleBlur} onChange={handleChange} ref={ref as any} />;
});
export const InputPassword = memo(ForwardRefPassword);

export const TextArea = memo(function TextArea({
  name,
  autoSize = { minRows: 3, maxRows: 3 },
  onBlur,
  onChange,
  ...props
}: TextAreaProps) {
  const handleBlur =
    onBlur &&
    ((event: FocusEvent<HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = event;
      onBlur({ name, value });
    });
  const handleChange =
    onChange &&
    ((event: ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = event;
      onChange({ name, value });
    });
  return <$Input.TextArea {...props} name={name} autoSize={autoSize} onBlur={handleBlur} onChange={handleChange} />;
});

const ForwardRefInput = forwardRef(function Input(initProps: any, ref: Ref<typeof $Input>) {
  if (initProps.inputType === 'number') {
    const { name, onBlur, onChange, ...props }: InputNumberProps = initProps;
    const handleBlur =
      onBlur &&
      ((event: React.FocusEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;
        onBlur({ name, value: Number(value) });
      });
    const handleChange =
      onChange &&
      ((value?: number) => {
        onChange({ name, value });
      });
    return <$InputNumber {...props} name={name} onBlur={handleBlur} onChange={handleChange as any} />;
  } else {
    const { inputType, name, onBlur, onChange, ...props }: InputProps = initProps;
    const getValue = (value: any) => {
      let data: any = value;
      if (data === '') {
        data = undefined;
      } else if (inputType === 'number' && !isNaN(Number(value))) {
        data = Number(value);
      }
      return data;
    };

    const handleBlur =
      onBlur &&
      ((event: FocusEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;
        onBlur({ name, value: getValue(value) });
      });
    const handleChange =
      onChange &&
      ((event: ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value, selectionStart },
        } = event;
        onChange({
          name,
          value: getValue(value),
          selectionStart: isNull(selectionStart) ? 0 : selectionStart,
        });
      });
    return <$Input {...props} name={name} onBlur={handleBlur} onChange={handleChange} ref={ref as any} />;
  }
});

const Input = memo(ForwardRefInput);

const StyledInput = styled(Input)`
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 4px 11px !important;
  line-height: 1.5 !important;

  &&::placeholder {
    color: #aaa;
  }
`;

export const InputWithWrapper = styled(Input)`
  && {
    line-height: 1.5;
    padding: 0;
    box-shadow: none;
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 44px;
  }

  &&:hover,
  &&:focus-within {
    border-width: 2px !important;
    border-color: ${theme.colorsPrimaryBlue} !important;
  }

  && .ant-input {
    padding: 4px 11px 4px 0;
    line-height: 1.5 !important;
    height: 100%;
  }

  && .ant-input-prefix {
    padding-left: 11px;
  }

  &&::placeholder {
    color: #aaa;
  }
`;

export default StyledInput;
