import React, { useCallback, useState, memo } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Form } from 'antd';
import * as yup from 'yup';

import PublicLayout from '../layout/public-layout';
import { Button } from '../components';
import { FormElementChangeEvent, Input, Select, TextArea } from '../components/forms';
import { Option } from '../types/models/user-management';
import theme from '../theme';
import validateWithSchema from '../utils/yup/validateWithSchema';
import { useQuery } from '../hooks/use-query';
import { getMessageTypes, sendContactUsMessage } from '../apis/public/contact-us';
import { IContactUsRequest } from '../types/models/contact-us';
import { InstructionWrapper, Heading1, Instruction } from '../layout/user-layout';

const InternalContainer = styled.div`
  max-width: ${theme.screenLgMax};
  margin: 0 auto;

  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 20px;
  }
`;

const HeaderBase = `
color: ${theme.colorsBlack};
letter-spacing: -0.4px;
line-height: 38px;
font-weight: bold;
`;

const Header = styled.h2`
  ${HeaderBase}
  font-size: 32px;
  padding-top: 40px;
  padding-bottom: 24px;
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    width: 100%;
    max-width: 160px;

    @media (max-width: ${theme.screenLgMax}) {
      max-width: 100%;
    }
  }
`;

const ContactUsForm = memo(function CF() {
  const [formData, setFormData] = useState<IContactUsRequest>({});
  const [formErrors, setFormErrors] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const [contactRequestedSuccessfully, setContactRequestedSuccessfully] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Please enter a valid email'),
    subject: yup.string().max(150, 'Subject should be a max of 150 characters').required('Subject is required'),
    body: yup.string().max(300, 'Message should be max of 300 characters').required('Message is required'),
    messageType: yup.string().required('Select a Question Type'),
  });

  const { data: messageTypes } = useQuery(getMessageTypes.QUERY_KEY, () => getMessageTypes());

  const handleSubmit = useCallback(async () => {
    const { errors } = await validateWithSchema(validationSchema, false, formData);
    setFormErrors(errors);

    if (errors && Object.keys(errors).length === 0) {
      setLoading(true);
      await sendContactUsMessage(formData);

      setLoading(false);
      setContactRequestedSuccessfully(true);
    }
  }, [formData, validationSchema]);

  const handleOnChange = useCallback(
    ({ name, value }: FormElementChangeEvent) => {
      if (name) {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    },
    [setFormData]
  );

  const questionTypes: Option[] = (messageTypes || []).map((item) => {
    return {
      value: item.id,
      label: item.title,
    };
  });

  return (
    <>
      {!contactRequestedSuccessfully && (
        <InternalContainer>
          <Row justify="center" gutter={32}>
            <Col xs={24} lg={20}>
              <Header>Contact Us</Header>
              <Form name="contactUs" onFinish={handleSubmit} layout="vertical" key="contactUs">
                <Row gutter={32}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label="*Email"
                      validateStatus={formErrors['email'] ? 'error' : ''}
                      help={formErrors['email'] && formErrors['email'].message}
                    >
                      <Input name="email" onChange={handleOnChange} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={32}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      key="subject"
                      label="*Subject"
                      validateStatus={formErrors['subject'] ? 'error' : ''}
                      help={formErrors['subject'] && formErrors['subject'].message}
                    >
                      <Input name="subject" onChange={handleOnChange} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label="*Question Type"
                      validateStatus={formErrors['messageType'] ? 'error' : ''}
                      help={formErrors['messageType'] && formErrors['messageType'].message}
                    >
                      <Select
                        name="messageType"
                        placeholder="Select One"
                        onChange={handleOnChange}
                        options={questionTypes}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={32}>
                  <Col xs={24}>
                    <Form.Item
                      label="*Your Message"
                      validateStatus={formErrors['body'] ? 'error' : ''}
                      help={formErrors['body'] && formErrors['body'].message}
                    >
                      <TextArea name="body" autoSize={{ minRows: 4, maxRows: 8 }} onChange={handleOnChange} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={32}>
                  <Col xs={24} lg={6}>
                    <Form.Item>
                      <StyledButton type="primary-blue" htmlType="submit" loading={loading} block>
                        Send Message
                      </StyledButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </InternalContainer>
      )}

      {contactRequestedSuccessfully && (
        <InstructionWrapper>
          <Heading1>Thank you for contacting us!</Heading1>
          <Instruction>We received your request and will contact you shortly</Instruction>
        </InstructionWrapper>
      )}
    </>
  );
});

const ContactUs = () => (
  <PublicLayout seoTitle="Contact Us">
    <ContactUsForm />
  </PublicLayout>
);

export default ContactUs;
