import axios from 'axios';
import { get as g } from 'lodash';

import { stringifyCollegeQueryFilter } from './event-calendar-utils';

import { CalendarEvent, EventbriteEvent, AttendanceType } from '../../containers/event-calendar/types';
import { API_URL, CMS_URL } from '../../constants';
import { createError } from '../error';
import responseParser from '../response-parser';
import { toJsTimeZoneName } from '../../utils/date';
import { CustomAxiosError } from '../../types/models/error';

export async function getEvents(filterQuery: any): Promise<any> {
  const limit = '-1';
  const filters = filterQuery && stringifyCollegeQueryFilter(filterQuery);

  try {
    const allEvents = await axios.get(`${CMS_URL}/items/events?limit=${limit}${filters ?? ''}`);
    return responseParser(allEvents);
  } catch (error) {
    throw createError(error as CustomAxiosError);
  }
}

getEvents.QUERY_KEY = 'public:getEvents';

// Eventbrite

function parseBriteEvent(data: EventbriteEvent): CalendarEvent {
  return {
    id: parseInt(data.eventbriteId),
    type: parseInt(data.type?.eventbriteId || ''),
    start_date_time: data.startDate,
    end_date_time: data.endDate,
    event_timezone: {
      timezone_value: toJsTimeZoneName(data.ianaTimezone) || '',
    },
    name: data.name,
    image: data.image,
    description: data.description,
    location: g(data.location, 'venue.address.localizedAddress'),
    online_location: g(data.location, 'online.url'),
    zipcode: parseInt(g(data.location, 'venue.address.zip')),
    attendance_type: g(data.location, 'online.url') ? AttendanceType.ONLINE : AttendanceType.IN_PERSON,
    eventbrite_link: data.eventbriteUrl,
    eventbriteEvent: true,
  };
}

export async function getBriteEvent(id: string): Promise<CalendarEvent> {
  try {
    const response = await axios.get(`${API_URL}/eventbrite-event/${id}`);
    const briteEvent: EventbriteEvent = responseParser(response);
    return parseBriteEvent(briteEvent);
  } catch (error) {
    throw createError(error as CustomAxiosError);
  }
}

getBriteEvent.QUERY_KEY = 'public:getBriteEvent';

export async function getBriteEvents(_params?: unknown): Promise<CalendarEvent[]> {
  try {
    /**
     * Turning off the call to the Event Brite for now. Conversation for that in ticket DGBPRO-5362.
     * Per attached email - Tammy asked that we do not show any Eventbrite generated events to the in the calendar.
     * No other changes are needed for now.
     */
    // const response = await axios.get(`${API_URL}/eventbrite-events`, { params });
    // const allBriteEvents: EventbriteEvent[] = responseParser(response);
    // return allBriteEvents.map(parseBriteEvent);
    return [];
  } catch (error) {
    throw createError(error as CustomAxiosError);
  }
}

getBriteEvents.QUERY_KEY = 'public:getBriteEvents';
