import styled from '@emotion/styled';
import { Form, Modal } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import AntdIcon from '@ant-design/icons-react';
import React, { useState } from 'react';

import { formSchema, ISectionItem } from './schema';
import { useScholarshipFinderQuery } from './scholarship-finder-context';

import { Button, Tooltip } from '../../../../components';
import { Select, FormElementChangeEvent } from '../../../../components/forms';
import { ScholarshipCMSType } from '../../../../types/models/scholarship-finder';

const Container = styled.div`
  max-height: 65vh;
  overflow-y: scroll;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MULTIPLE_SELECT_PLACEHOLDER = 'Select one or more';

const FilterItemRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButtonDiv = styled.div`
  display: flex;
  align-items: center;
`;

export function ScholarshipFinderModal({ clearAllFilters }: { clearAllFilters: any }) {
  const [state, setState] = useState<Partial<ScholarshipCMSType>>({});
  const [visible, toggleVisible] = useState(false);

  const { setFilterQuery, queryOptions } = useScholarshipFinderQuery();

  const handleOpen = () => {
    setState((current) => ({ ...current, ...queryOptions }));
    toggleVisible(true);
  };

  const handleClose = () => {
    toggleVisible(false);
  };

  const clearFilters = () => {
    setState((current) => {
      const clearedModalFilters = Object.keys(current).reduce((result, key) => ({ ...result, [key]: undefined }), {});
      return clearedModalFilters;
    });
    clearAllFilters();
    toggleVisible(false);
  };

  const saveFilter = () => {
    setFilterQuery(state);
    handleClose();
  };

  const getFieldChangeHandler =
    (filterKey: keyof ScholarshipCMSType) =>
    ({ value }: FormElementChangeEvent) => {
      setState((current) => ({
        ...current,
        [filterKey]: value,
      }));
    };

  const renderSelectItem = ({ label, name, options, tooltip }: ISectionItem) => {
    let hasMultipleMode = true;
    let placeholder = MULTIPLE_SELECT_PLACEHOLDER;

    if (
      [
        'citizenship',
        'field_of_study',
        'geographic_eligibility',
        'undergraduate_level',
        'amount_of_award',
        'family_income',
      ].includes(name)
    ) {
      hasMultipleMode = false;
      placeholder = 'Select one';
    }
    return (
      <Form.Item label={label} key={name}>
        <FilterItemRow>
          <Select
            style={{ maxWidth: 700, flex: 1 }}
            mode={hasMultipleMode ? 'multiple' : ('default' as any)}
            placeholder={placeholder}
            value={state[name]}
            name={name}
            options={options}
            onChange={getFieldChangeHandler(name)}
          />
          {tooltip && <Tooltip title={tooltip} />}
        </FilterItemRow>
      </Form.Item>
    );
  };

  return (
    <>
      <Button key="filter" type="link" onClick={handleOpen}>
        <StyledButtonDiv>
          <AntdIcon type={FilterFilled} />
          <div style={{ marginLeft: 5 }}>More filters</div>
        </StyledButtonDiv>
      </Button>
      <Modal
        visible={visible}
        title="More Filters"
        closable={true}
        onCancel={handleClose}
        footer={[
          <StyledDiv key="1">
            <Button key="clear" type="link" onClick={clearFilters}>
              Clear Filters
            </Button>
            <div>
              <Button key="cancel" type="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button key="apply" type="primary-blue" onClick={saveFilter}>
                Apply Filters
              </Button>
            </div>
          </StyledDiv>,
        ]}
      >
        <Container>{formSchema.map(renderSelectItem)}</Container>
      </Modal>
    </>
  );
}
