import { Col, Dropdown, Input, Menu, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { merge } from 'lodash';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { DEFAULT_FORM_DATA, createFormStateFromSchema } from '../../utils/form';
import { Button, Modal } from '../../components';
import { FormStatus, IFormDataSchema, IFormSchema, IFormState } from '../../types/form';
import TabNavigation, { ITabNavigationDataSourceItem } from '../../components/common/tab-navigation';
import { flagEssay, getReaderFormsData, saveReaderFormData } from '../../apis/users/stages';
import { DEFAULT_CONFIRMATION_MESSAGE } from '../myhsf/form';
import FormRender from '../../components/forms/form-render';
import { IUser } from '../../types/models/auth';
import { PageContainer } from '../../components/common/page-container';
import PageHeader from '../../components/common/page-header';
import SEO from '../../components/seo';
import { StageType } from '../../types/models/program-management';
import { getUser } from '../../state/selectors/auth';
import { navigateTo } from '../../state/actions/routing';
import { parseUrlQueryParams } from '../../utils/common';
import { tenantLabel } from '../../siteContent';

const StyledPageContainer = styled(PageContainer)`
  width: 100%;
  padding: 0;
`;
const OptionMenuGroup = styled(Menu)`
  width: 180px;
`;

const dashboardUrl = '/myhsf/dashboard';

type EssayProps = {
  stageId: string;
};

export default function Essay({ stageId, location }: RouteComponentProps<EssayProps>) {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  const userInfo: IUser | null = useSelector(getUser);
  const { requestId } = parseUrlQueryParams(location?.search);
  const generateBreadcrumb = useCallback(() => {
    return [
      <Link key="1" to={dashboardUrl}>
        {tenantLabel} Dashboard
      </Link>,
      'Read & Evaluate Essay',
    ];
  }, []);

  const [evaluateEssayFormSchema, setEvaluateEssayFormSchema] = useState<IFormSchema>();
  const [evaluateEssayFormData, setEvaluateEssayFormData] = useState<IFormDataSchema>();
  const [evaluateEssayFormState, setEvaluateEssayFormState] = useState<IFormState>();
  const [evaluateEssayFormStatus, setEvaluateEssayFormStatus] = useState<FormStatus>();
  const [evaluateEssayStageType, setEvaluateEssayStageType] = useState<StageType>();
  const [evaluateEssayConfirmation, setEvaluateEssayConfirmation] = useState<string>(DEFAULT_CONFIRMATION_MESSAGE);
  const [essayFormSchema, setEssayFormSchema] = useState<IFormSchema>();
  const [essayFormData, setEssayFormData] = useState<IFormDataSchema>();
  const [essayFormState, setEssayFormState] = useState<IFormState>();
  const [essayFormStatus, setEssayFormStatus] = useState<FormStatus>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [programId, setProgramId] = useState<string>();
  const [programName, setProgramName] = useState<string>();

  const [selectedTab, setSelectedTab] = useState<string>('essay');

  const navigateToDashboard = useCallback(() => {
    dispatch(navigateTo('/myhsf') as any);
  }, [dispatch]);

  const handleTabChange = useCallback((item: ITabNavigationDataSourceItem) => {
    setSelectedTab(item.value);
  }, []);

  const tabDataSource: ITabNavigationDataSourceItem[] = [
    {
      label: 'Essay',
      value: 'essay',
    },
    {
      label: 'Evaluation',
      value: 'essayEvaluation',
    },
  ];
  const renderTabs = () => {
    return <TabNavigation dataSource={tabDataSource} onTabChange={handleTabChange} />;
  };

  // initiate request after `formSchema` is set from the first request
  const { isLoading: isLoadingReaderFormsData } = useQuery(
    [getReaderFormsData.QUERY_KEY, { stageId, requestId }],
    ({ queryKey }: any) => getReaderFormsData(queryKey[1].stageId, queryKey[1].requestId),
    {
      enabled: !!stageId,
      onSuccess: (data) => {
        const { readerStage: evaluateEssayStage, studentStage: essayStage, id: programId, name: programName } = data;
        setErrorMessage(null);

        setProgramId(programId);
        setProgramName(programName);
        // Set Evaluate Essay Stage Details
        setEvaluateEssayStageType(evaluateEssayStage?.stageType);
        setEvaluateEssayConfirmation(evaluateEssayStage?.confirmation);
        if (evaluateEssayStage?.formSchema) {
          setEvaluateEssayFormSchema(evaluateEssayStage?.formSchema);
          // send default `formState` earlier than `formData`
          setEvaluateEssayFormState(createFormStateFromSchema(evaluateEssayStage?.formSchema));
          setEvaluateEssayFormStatus(evaluateEssayStage?.formStatus || FormStatus.InProgress);
          setEvaluateEssayFormData(evaluateEssayStage?.formPayload || DEFAULT_FORM_DATA);
        }
        if (evaluateEssayStage?.formState) {
          setEvaluateEssayFormState(
            evaluateEssayFormState
              ? merge({}, evaluateEssayFormState, evaluateEssayStage?.formState)
              : evaluateEssayStage?.formState
          );
        }

        // Set Essay Stage Details
        // setEssayStageType(essayStage.stageType);
        if (essayStage?.formSchema) {
          setEssayFormSchema(essayStage.formSchema);
          // send default `formState` earlier than `formData`
          setEssayFormState(createFormStateFromSchema(essayStage.formSchema));
          setEssayFormStatus(FormStatus.Completed);
          setEssayFormData(essayStage.formPayload || DEFAULT_FORM_DATA);
        }
        if (essayStage?.formState) {
          if (essayStage.formState && essayStage.formState.currentPosition) {
            essayStage.formState.currentPosition.sectionIndex = 0;
          }
          setEssayFormState(essayFormState ? merge({}, essayFormState, essayStage.formState) : essayStage.formState);
        }
      },
      onError: (err: any) => {
        setErrorMessage(err.message);
      },
    }
  );

  const handleSaveEvaluateEssayForm = useCallback(
    async (formPayload: IFormDataSchema, formState: IFormState) => {
      if (!programId || !stageId || !evaluateEssayStageType || !requestId) {
        return;
      }
      setIsSaving(true);
      try {
        await saveReaderFormData({
          programId,
          stageId,
          stageType: evaluateEssayStageType,
          formPayload,
          formState,
          formStatus: FormStatus.InProgress,
          requestId,
        });
        setErrorMessage(null);
        setEvaluateEssayFormData(formPayload);
        setEvaluateEssayFormState(formState);

        Modal.info({
          title: 'Your form has been successfully saved.',
          onOk: () => {
            navigateToDashboard();
          },
        });
      } catch (err: any) {
        setErrorMessage(err.message);
        Modal.error({
          title: err.message,
        });
      } finally {
        setIsSaving(false);
      }
    },
    [evaluateEssayStageType, programId, requestId, stageId]
  );

  const handleSubmitEvaluateEssayForm = useCallback(
    async (formPayload: IFormDataSchema, formState: IFormState) => {
      if (!programId || !stageId || !evaluateEssayStageType) {
        return;
      }
      setIsSubmitting(true);
      try {
        await saveReaderFormData({
          programId,
          stageId,
          stageType: evaluateEssayStageType,
          formPayload,
          formState,
          formStatus: FormStatus.Completed,
          requestId,
        });
        setErrorMessage(null);
        setEvaluateEssayFormData(formPayload);
        setEvaluateEssayFormState(formState);
        setIsSubmitted(true);
      } catch (err: any) {
        setErrorMessage(err.message);
        Modal.error({
          title: err.message,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [programId, stageId, evaluateEssayStageType, requestId, navigateToDashboard]
  );

  const handleConfirmationClose = useCallback(() => {
    navigateToDashboard();
  }, [navigateToDashboard]);

  const isLoadingEvaluateEssay = !errorMessage && isLoadingReaderFormsData;
  const isEvaluateEssayReady =
    !!evaluateEssayFormSchema && !!evaluateEssayFormData && !!evaluateEssayFormState && !!evaluateEssayFormStatus;

  const isLoadingEssay = !errorMessage && isLoadingReaderFormsData;
  const isEssayReady = !!essayFormSchema && !!essayFormData && !!essayFormState && !!essayFormStatus;

  const renderEssay = () => {
    return (
      <FormRender
        schema={essayFormSchema}
        initialData={essayFormData}
        initialFormState={essayFormState}
        formStatus={essayFormStatus}
        isSubmitted={false}
        isLoading={isLoadingEssay}
        isReady={isEssayReady}
        isSaving={isSaving}
        errorMessage={errorMessage}
        confirmationMessage={DEFAULT_CONFIRMATION_MESSAGE}
        isDebug={false}
        onSaveForm={() => ({})}
        onSubmit={() => ({})}
        onConfirmationClose={() => ({})}
        isAdminView={true}
      />
    );
  };

  const renderEvaluationForm = () => {
    return (
      <FormRender
        schema={evaluateEssayFormSchema}
        initialData={evaluateEssayFormData}
        initialFormState={evaluateEssayFormState}
        formStatus={evaluateEssayFormStatus}
        isSubmitted={isSubmitted}
        isLoading={isLoadingEvaluateEssay}
        isReady={isEvaluateEssayReady}
        isSaving={isSubmitting}
        errorMessage={errorMessage}
        confirmationMessage={evaluateEssayConfirmation}
        isDebug={false}
        onSaveForm={handleSaveEvaluateEssayForm}
        onSubmit={handleSubmitEvaluateEssayForm}
        onConfirmationClose={handleConfirmationClose}
      />
    );
  };

  const handleFlagEssay = () => {
    // eslint-disable-next-line prefer-const
    let modal: any;
    let reason: string;

    const handleEssayReasonEnter = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      reason = event.target.value;
      modal.update({
        okButtonProps: {
          className: `ok-btn confirm-ok-btn`, //TODO: may this dynamically determined
          disabled: event.target.value.length === 0,
        },
      });
    };

    modal = Modal.confirm({
      title: 'Flag Essay',
      okText: 'Save',
      okButtonProps: {
        disabled: true,
      },
      content: (
        <Row>
          <Row>Please provide an explanation. Only Admin will see that the essay has been flagged.</Row>
          <Input.TextArea onChange={handleEssayReasonEnter} allowClear={true} autoSize={{ minRows: 6 }} />
        </Row>
      ),
      onOk: async () => {
        await flagEssay({
          reason,
          requestId,
          stageId: stageId || '',
          userId: userInfo?.id || '',
        });
        navigateToDashboard();
      },
    });
  };

  const menu = (
    <OptionMenuGroup>
      <Menu.Item key={'flagEssay'} onClick={handleFlagEssay}>
        Flag Essay
      </Menu.Item>
    </OptionMenuGroup>
  );

  return (
    <>
      <SEO title="Essay" />
      <StyledPageContainer>
        {/* Move PageHeader into component */}
        <PageHeader
          title={programName}
          breadcrumb={generateBreadcrumb()}
          actions={[
            <Row key="1" gutter={[16, 0]} align="middle" justify="end">
              <Col>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <Button type="link">
                    Options
                    <ExpandMore />
                  </Button>
                </Dropdown>
              </Col>
            </Row>,
          ]}
          extra={renderTabs()}
        />
        <PageContainer>
          {selectedTab === 'essay' && renderEssay()}
          {selectedTab === 'essayEvaluation' && renderEvaluationForm()}
        </PageContainer>
      </StyledPageContainer>
    </>
  );
}
