import axios from 'axios';

import { createError } from './error';
import responseParser from './response-parser';

import { API_URL } from '../constants';
import { IPrivilegesData } from '../types/models/privileges';
import { CustomAxiosError } from '../types/models/error';

export async function getUserPrivileges(): Promise<IPrivilegesData[]> {
  try {
    // need to consult with be for correct api
    const url = `${API_URL}/roles`;
    const response = await axios.get(url);
    const data: IPrivilegesData[] = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

// // for later extension
// export async function getUserRoles(userId: string): Promise<IPrivilegeRoles[]> {
//   try {
//     // need to consult with be for correct api
//     const url = `${API_URL}/admin/${userId}/roles`;
//     const response = await axios.get(url);
//     const data: IPrivilegeRoles[] = responseParser(response);
//     return data;
//   } catch (err) {
//     throw createError(err);
//   }
// }

// // for later extension
// export async function getUserPermissions(userId: string): Promise<IPrivilegePermissions[]> {
//   try {
//     // need to consult with be for correct api
//     const url = `${API_URL}/admin/${userId}/permissions`;
//     const response = await axios.get(url);
//     const data: IPrivilegePermissions[] = responseParser(response);
//     return data;
//   } catch (err) {
//     throw createError(err);
//   }
// }
