import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { find, get } from 'lodash';

import { NoCoaches } from './no-coaches';

import SEO from '../../../../components/seo';
import { getUser } from '../../../../state/selectors/auth';
import HorizontalSecondaryMenu from '../../../../components/common/horizontal-secondary-menu';
import PageHeader from '../../../../components/common/page-header';
import { IUser } from '../../../../types/models/auth';
import { InternalContainer } from '../../../../templates/basic-styles';
import { getAssignedCoaches } from '../../../../apis/users/coaches-match';
import UserDetailsContainer from '../../mentorship/common/user-details-container';
import { StyledMessage } from '../../scholarship/common/styles';
import Loader from '../../../../components/common/loader';
import { tenantLabel } from '../../../../siteContent';
import CoachCompleteSurveyModal from '../coach-complete-survey-modal/coach-complete-survey-modal';
import { Coach } from '../../../../types/models/coaching';
import { openChat, setIsLoadingChat } from '../../../../state/actions/message';
import Chat from '../../../../components/chat/index';
import { isRealTimeChatEnabled as $isRealTimeChatEnabled } from '../../../../state/selectors/tenant';

const StyledHorizontalMenu = styled.div`
  background-color: white;
`;

const SectionWrapper = styled.div`
  margin: 20px 0;
  background-color: white;
  position: relative;
`;

const CoachesList: React.FC<RouteComponentProps> = (_: RouteComponentProps) => {
  const menuItems = [
    {
      name: 'activeCoaches',
      value: 'Active Coach',
      slug: '/coaches',
    },
    {
      name: 'pasCoaches',
      value: 'Past Coaches',
      slug: '/coaches',
    },
  ];

  const dispatch = useDispatch();
  const loggedUser: IUser | null = useSelector(getUser);
  const [activeNavItem, setActiveNavItem] = useState(menuItems[0]);
  const [completeSurveyModalVisible, setCompleteSurveyModalVisible] = useState(false);
  const [selectedCoachId, setSelectedCoachId] = useState('');
  const isRealTimeChatEnabled = useSelector($isRealTimeChatEnabled());

  const handleOpenCoachBio = (userId: string) => {
    navigate(`/myhsf/my-coaches/${userId}`);
  };

  const handleSendMessage = (userId: string) => {
    dispatch(setIsLoadingChat(true));
    dispatch(openChat(userId));
  };

  const handleMarkAsComplete = (userId: string) => {
    setSelectedCoachId(userId);
    setCompleteSurveyModalVisible(true);
  };

  const buttons = [
    {
      title: 'Mark as Complete',
      type: 'primary',
      handler: handleMarkAsComplete,
    },
    {
      title: 'Coach Bio',
      type: 'primary',
      handler: handleOpenCoachBio,
    },
    {
      title: 'Send Message',
      type: 'primary-blue',
      handler: handleSendMessage,
      opensChat: true,
    },
  ];

  const isActiveCoachesTab = (): boolean => {
    return activeNavItem.name === 'activeCoaches';
  };

  const {
    data: coaches,
    isFetching,
    error,
    refetch: refetchCoaches,
  } = useQuery(
    [getAssignedCoaches.QUERY_KEY, { active: isActiveCoachesTab() }],
    ({ queryKey }) => getAssignedCoaches(queryKey[1] as { active: boolean }),
    { enabled: !!loggedUser }
  );

  const handleSecondaryMenuClick = (sectionName: string) => {
    const navItem = find(menuItems, (item: { name: string }) => {
      return item.name === get(sectionName, 'key');
    });
    if (navItem) {
      setActiveNavItem(navItem);
    }
  };

  const coachesItems =
    coaches &&
    coaches.map((item: Coach) => {
      const buttonsList = item.completedByCoachee ? buttons.slice(1) : buttons;

      return (
        <SectionWrapper key={item.id}>
          <UserDetailsContainer userData={item} buttonsList={buttonsList} />
        </SectionWrapper>
      );
    });

  return (
    <>
      <SEO title="My Coaches" />
      <PageHeader
        title="My Coaches"
        breadcrumb={[
          <Link key="1" to="/myhsf">
            {tenantLabel} Dashboard
          </Link>,
          'My Coaches',
        ]}
        fullWidth
        isResponsive
        noShadow
      />
      <StyledHorizontalMenu>
        <HorizontalSecondaryMenu activeItem={activeNavItem} handleClick={handleSecondaryMenuClick} items={menuItems} />
      </StyledHorizontalMenu>
      <InternalContainer>
        {!error && isFetching && <Loader />}
        {!error &&
          !isFetching &&
          (coachesItems && coachesItems.length > 0 ? (
            coachesItems
          ) : (
            <NoCoaches active={isActiveCoachesTab()} afterRequestCoach={refetchCoaches} />
          ))}
        {error && <StyledMessage>{(error as Error).message}</StyledMessage>}
      </InternalContainer>
      <CoachCompleteSurveyModal
        closeModal={() => {
          setCompleteSurveyModalVisible(false);
          setSelectedCoachId('');
          refetchCoaches();
        }}
        coachId={selectedCoachId}
        isVisible={completeSurveyModalVisible}
      />
      {isRealTimeChatEnabled && <Chat />}
    </>
  );
};

export default CoachesList;
