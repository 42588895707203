import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { connect, ConnectedProps } from 'react-redux';

import MentorInfo from './mentor-info/mentor-info';

import theme from '../../../../../theme';
import UserContent from '../../../../../components/common/user-content';
import { UserContentLayoutType } from '../../../../../types/layout';
import { Skills } from '../../common';
import { RootState } from '../../../../../state/reducers';
import UserBioContentCard from '../../../../../components/user/user-bio/user-bio-content-card';

const UserContentCss = css`
  padding-top: 30px;
  background-color: ${theme.colorsLightestGrey};
`;

const MentorSkillRow = styled.div`
  display: list-item;
  list-style: disc inside none;
`;

const mapState = ({ mentorBioState }: RootState) => ({
  mentor: mentorBioState.mentor,
});

const connector = connect(mapState, {});

type MentorDashboardProps = ConnectedProps<typeof connector>;

const MentorDashboard: React.FC<MentorDashboardProps> = (props: MentorDashboardProps) => {
  const mentorSkills = Skills.filter((skill) => props.mentor.skills?.includes(skill.value)).map((skill) => {
    return <MentorSkillRow key={skill.value}>{skill.label}</MentorSkillRow>;
  });

  return (
    <React.Fragment>
      <MentorInfo />
      <UserContent type={UserContentLayoutType.DASHBOARD} css={UserContentCss}>
        <UserBioContentCard title={'Why I Want To Be a Mentor'} content={props.mentor.mentorshipReason} />
        {mentorSkills.length > 0 && <UserBioContentCard title={'What I Can Help You With'} content={mentorSkills} />}
      </UserContent>
    </React.Fragment>
  );
};

export default connector(MentorDashboard) as React.FC;
