import axios, { AxiosResponse } from 'axios';

import { API_URL, TENANT } from '../../constants';
import { IContactUsRequest, IMessageType } from '../../types/models/contact-us';
import { IRequestReadResponse } from '../../types/models/program-management';
import { CustomAxiosError } from '../../types/models/error';
import { createError } from '../error';
import responseParser from '../response-parser';

const options = { headers: { tenant: TENANT } };

const MESSAGE_TYPES_DUMMY_RESPONSE = {
  data: {
    result: {
      items: [
        {
          id: '379f5fff-bf1b-4818-95dc-e8b7577829e3',
          title: 'Application Status',
        },
        {
          id: '64084d12-5092-4f2a-ac6d-b334b267af9c',
          title: 'Application Update',
        },
        {
          id: '9a593dc5-2456-4bb2-b94b-751074507f4d',
          title: 'Disbursement Question',
        },
        {
          id: '8936fe47-e0ae-4432-a0bb-a3eda7ef3ee8',
          title: 'Document Question',
        },
        {
          id: '09bfa869-4b9d-4dc0-9e15-8cc0d2758d06',
          title: 'Eligibility Question',
        },
        {
          id: 'fd753b0c-756c-4ffd-8cdd-769ce10d0c97',
          title: 'General Question ',
        },
        {
          id: '5c7fa803-7c61-492b-84c5-7834885cd4d1',
          title: 'Recommender Question',
        },
        {
          id: '8237d24a-9fb9-4b9d-bf94-1fc8953f95ec',
          title: 'Scholarship Read Question',
        },
        {
          id: '24619495-b35b-49ea-8346-2fb40f82bb82',
          title: 'Technical Concern',
        },
      ],
    },
    totalCount: 10,
  },
};

export async function getMessageTypes(): Promise<IMessageType[]> {
  const url = `${API_URL}/contact-us/message-types`;
  try {
    let response = null;

    try {
      response = await axios.get(url);
    } catch (error) {
      if ((error as CustomAxiosError).response) {
        response = await Promise.resolve(MESSAGE_TYPES_DUMMY_RESPONSE);
      } else {
        throw error;
      }
    }

    const result = responseParser(response as AxiosResponse).items.map(
      (item: any): IMessageType => ({
        id: item.id,
        title: item.title,
      })
    );

    return result;
  } catch (error) {
    throw createError(error as CustomAxiosError);
  }
}

getMessageTypes.QUERY_KEY = 'public:getMessageTypes';

/**
 * Reader's request to read new essay
 *
 * @param {string} programId
 * @param {StudentType[]} studentTypes
 */
export async function sendContactUsMessage(formData: IContactUsRequest): Promise<IRequestReadResponse> {
  const url = `${API_URL}/contact-us`;
  try {
    const response = await axios.post(url, formData, options);
    const data = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}
