import React, { useState, useCallback } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import * as yup from 'yup';
import { useMutation } from 'react-query';

import { FormElementChangeEvent, Input, PhoneNumber } from '../../forms';
import { Button } from '../..';
import { FieldErrors } from '../../../types/form';
import validateWithSchema from '../../../utils/yup/validateWithSchema';
import { createCollegeResourceSuggestion } from '../../../apis/users/college-navigator';
import { ICollegeResourceData } from '../../../types/models/college-navigator';

export interface ICollegeResourceFormData {
  collegeResourcesId: number;
  data: {
    functionRoleId: string;
    contactName?: string;
    contactTitle?: string;
    officeDepartmentName?: string;
    email?: string;
    phone?: string;
    website?: string;
    address?: string;
  };
}

const CollegeResourceEdit = ({ resource }: { resource: ICollegeResourceData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<Partial<ICollegeResourceData>>(Object.assign({}, resource));
  const [formErrors, setFormErrors] = useState<FieldErrors<{ [key: string]: string }>>({});

  const { mutateAsync: sendSuggestion, isLoading: isSendingSuggestion } = useMutation<
    ICollegeResourceData,
    unknown,
    Partial<ICollegeResourceData>
  >((formData) => createCollegeResourceSuggestion(formData));

  const yupSchema: Record<string, yup.StringSchema<string | null | undefined>> = {
    contactName: yup.string().nullable(true),
    contactTitle: yup.string().nullable(true),
    officeDepartmentName: yup.string().nullable(true),
    email: yup.string().nullable(true).email('Please enter a valid email'),
    phone: yup.string().nullable(true),
    website: yup.string().nullable(true),
    address: yup.string().nullable(true),
  };

  const validationSchema = yup.object().shape(yupSchema);

  const validate = useCallback(async () => {
    const { errors } = await validateWithSchema(validationSchema, false, formData);
    return errors;
  }, [formData, validationSchema]);

  const handleFormOnChange = useCallback(({ name, value }: FormElementChangeEvent) => {
    if (name) {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  }, []);

  const handleFormOnBlur = useCallback(async () => {
    if (isFormSubmitted) {
      setFormErrors(await validate());
    }
  }, [isFormSubmitted, validate]);

  const handleSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      const errors = await validate();
      setFormErrors(errors);
      setIsFormSubmitted(true);
      if (errors && Object.keys(errors).length === 0) {
        await sendSuggestion(formData);
        setFormData({});
        setOpenModal(false);
        Modal.success({
          title: `Suggest Edit: ${formData?.officeDepartmentName}`,
          content: 'Your edits have been submitted to the System Administrator for review.',
        });
      }
    },
    [formData, validate, sendSuggestion]
  );

  const showModal = () => {
    setFormData(Object.assign({}, resource));
    setOpenModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        type="link"
        onClick={() => {
          showModal();
        }}
      >
        Suggest Edit
      </Button>
      <Modal
        visible={openModal}
        title={`Suggest Edit: ${resource.functionRoleName}`}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="send" type="primary-blue" onClick={handleSubmit} loading={isSendingSuggestion}>
            Send
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <Form name="suggest-edit-resource-data" colon={false}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Contact Name: "
                validateStatus={formErrors['contactName'] ? 'error' : ''}
                help={formErrors['contactName'] && formErrors['contactName'].message}
              >
                <Input
                  name="contactName"
                  value={formData?.contactName}
                  onChange={handleFormOnChange}
                  onBlur={handleFormOnBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Contact Title: "
                validateStatus={formErrors['contactTitle'] ? 'error' : ''}
                help={formErrors['contactTitle'] && formErrors['contactTitle'].message}
              >
                <Input
                  name="contactTitle"
                  value={formData?.contactTitle}
                  onChange={handleFormOnChange}
                  onBlur={handleFormOnBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Office/Department Name: "
                validateStatus={formErrors['officeDepartmentName'] ? 'error' : ''}
                help={formErrors['officeDepartmentName'] && formErrors['officeDepartmentName'].message}
              >
                <Input
                  name="officeDepartmentName"
                  value={formData?.officeDepartmentName}
                  onChange={handleFormOnChange}
                  onBlur={handleFormOnBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Email: "
                validateStatus={formErrors['email'] ? 'error' : ''}
                help={formErrors['email'] && formErrors['email'].message}
              >
                <Input name="email" value={formData?.email} onChange={handleFormOnChange} onBlur={handleFormOnBlur} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Phone Number: "
                validateStatus={formErrors['phone'] ? 'error' : ''}
                help={formErrors['phone'] && formErrors['phone'].message}
              >
                <PhoneNumber
                  name="phone"
                  value={formData?.phone || ''}
                  onChange={handleFormOnChange}
                  onBlur={handleFormOnBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Website URL: "
                validateStatus={formErrors['website'] ? 'error' : ''}
                help={formErrors['website'] && formErrors['website'].message}
              >
                <Input
                  name="website"
                  value={formData?.website}
                  onChange={handleFormOnChange}
                  onBlur={handleFormOnBlur}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item
                label="Address: "
                validateStatus={formErrors['address'] ? 'error' : ''}
                help={formErrors['address'] && formErrors['address'].message}
              >
                <Input
                  name="address"
                  value={formData?.address}
                  onChange={handleFormOnChange}
                  onBlur={handleFormOnBlur}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CollegeResourceEdit;
