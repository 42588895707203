import React from 'react';
import { graphql, Link } from 'gatsby';
import { Breadcrumb, Col, Row, Card } from 'antd';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import DOMPurify from 'isomorphic-dompurify';
import { find } from 'lodash';
import NavigateNext from '@material-ui/icons/NavigateNext';

import { Header, StyledH6, InternalContainer, PageContent } from './basic-styles';

import HorizontalSecondaryMenu from '../components/common/horizontal-secondary-menu';
import { NavItem } from '../types/cms/models/nav-item';
import PublicLayout from '../layout/public-layout';
import SectionNavComponent from '../components/common/section-navigation';
import { getRealNodes } from '../utils/contentFilter';
import { navigateTo } from '../state/actions/routing';
import { NavigationWrapper } from '../components/common/page-header';
import { TitleCol } from '../containers/college-finder/styles';
import theme from '../theme';
import { useI18NContext } from '../i18n';
import { useResponsive } from '../hooks/use-responsive';
import { TENANT_NAME } from '../constants';

type PartnersProps = {
  data: {
    topNavItems: {
      nodes: TypeNavItem[];
    };
    activeNavItem: TypeNavItem;
    partners: {
      nodes: Partner | any;
    };
    allPartners: {
      nodes: Partner | any;
    };
    sideNavItems: {
      nodes: SectionNavItem[];
    };
    activeSideNavItem: SectionNavItem;
  };
};

export type Partner = {
  directusId: number;
  partner_slug: string;
  partner_title: string;
  partner_cta_label: string;
  partner_type: number;
  display_partner_page: boolean;
  partner_logo: { data: { full_url: string } };
};

type TypeNavItem = {
  slug: string;
  partner_type_name: string;
  partner_type_value: string;
  translations: any;
  directusId: number;
};

type SectionNavItem = {
  partner_section_name: string;
  partner_section_value: string;
  translations: any;
  slug: string;
  directusId: number;
};

const PartnersContainer = styled(InternalContainer)`
  padding-top: 40px;
`;

const StyledHeader = styled(Header)`
  padding-top: 0;
`;

const H6 = styled(StyledH6)`
  margin-bottom: 20px;
`;

const TitleBreadcrumbsWrapper = styled.div`
  max-width: ${theme.screenLgMax};
  margin: 0 auto;
`;

const StyledPageContent = styled(PageContent)`
  p {
    padding-bottom: 40px;
  }
`;

const PartnerImageContainer = styled(Col)`
  height: 258px;
  margin-bottom: 30px;
  padding-right: 30px;
  overflow: hidden;

  div {
    height: 100%;
    padding: 0;
  }
`;

const StyledCard = styled(Card)<{ disabled: boolean }>`
  width: 258px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 4px;
  border: 2px solid ${theme.colorsMediumGrey};

  &:hover {
    ${(props) => (props.disabled ? '' : `border: 2px solid ${theme.colorsSecondaryBlue};`)}
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
  }
`;

// The query used to provide the page data
export const query = graphql`
  query ($type: String!, $section: String) {
    topNavItems: allDirectusPartnerType {
      nodes {
        slug
        partner_type_name
        partner_type_value
        translations {
          language
          partner_type_description
        }
      }
    }

    activeNavItem: directusPartnerType(partner_type_name: { eq: $type }) {
      partner_type_name
      partner_type_value
      slug
      directusId
      translations {
        language
        partner_type_description
      }
    }

    partners: allDirectusPartner(
      filter: { partner_section: { partner_section_name: { eq: $section } }, status: { eq: "published" } }
      sort: { fields: [sort] }
    ) {
      nodes {
        directusId
        partner_slug
        partner_title
        partner_cta_label
        partner_type
        display_partner_page
        partner_logo {
          data {
            full_url
          }
        }
      }
    }

    allPartners: allDirectusPartner(filter: { status: { eq: "published" } }, sort: { fields: [sort] }) {
      nodes {
        directusId
        partner_slug
        partner_title
        partner_cta_label
        partner_type
        display_partner_page
        partner_logo {
          data {
            full_url
          }
        }
      }
    }

    sideNavItems: allDirectusPartnerSection(
      filter: { partner_type: { partner_type_name: { eq: $type } }, status: { eq: "published" } }
      sort: { fields: [sort] }
    ) {
      nodes {
        partner_section_name
        partner_section_value
        slug
        translations {
          language
          partner_section_description
        }
      }
    }

    activeSideNavItem: directusPartnerSection(partner_section_name: { eq: $section }) {
      partner_section_name
      partner_section_value
      slug
      translations {
        language
        partner_section_description
      }
    }
  }
`;

const Partners = ({
  data: { topNavItems, activeNavItem, sideNavItems, activeSideNavItem, partners, allPartners },
}: PartnersProps) => {
  const dispatch = useDispatch();
  const { translate, translateSlug } = useI18NContext();
  const { xl } = useResponsive();
  const isMobile = !xl;

  const mappedMenuItems: NavItem[] = getRealNodes(topNavItems).map((item: TypeNavItem) => {
    return {
      value: item.partner_type_value,
      name: item.partner_type_name,
      slug: item.slug,
    };
  });
  const typeItem: NavItem = {
    value: activeNavItem.partner_type_value,
    name: activeNavItem.partner_type_name,
    slug: activeNavItem.slug,
  };

  const mappedTertiaryMenu: NavItem[] = getRealNodes(sideNavItems).map((item: SectionNavItem) => {
    return {
      value: item.partner_section_value,
      name: item.partner_section_name,
      slug: item.slug,
    };
  });
  const sectionItem: NavItem = {
    value: activeSideNavItem.partner_section_value,
    name: activeSideNavItem.partner_section_name,
    slug: activeSideNavItem.slug,
  };

  const displayPartners: Partner[] =
    activeSideNavItem.partner_section_name === 'all' ? allPartners.nodes : partners.nodes;

  const handleSecondaryMenuClick = (e: any) => {
    const activeItem = find(mappedMenuItems, (item) => {
      return item.name === e.key;
    });

    if (activeItem) {
      dispatch(navigateTo(activeItem.slug) as any);
    }
  };

  const handleTertiaryMenuClick = (name: string, slug: string) => {
    const activeItem = find(mappedTertiaryMenu, (item) => {
      return item.name === name;
    });

    if (activeItem) {
      dispatch(navigateTo(slug) as any);
    }
  };

  const handleCardClick = (slug: string) => {
    dispatch(
      navigateTo(translateSlug(slug), {
        state: { previousLink: typeof window !== 'undefined' ? window.location.pathname : '' },
      }) as any
    );
  };

  const generateBreadcrumb = (name?: string) => {
    return [
      <Link key="1" to="/">
        {name}
      </Link>,
      `About ${TENANT_NAME ?? ''}: ${TENANT_NAME ?? ''} Partners`,
    ];
  };

  return (
    <PublicLayout seoTitle="Partners">
      <TitleBreadcrumbsWrapper>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb('Home').map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <TitleCol>{TENANT_NAME} Partners</TitleCol>
      </TitleBreadcrumbsWrapper>
      <HorizontalSecondaryMenu activeItem={typeItem} handleClick={handleSecondaryMenuClick} items={mappedMenuItems} />
      <PartnersContainer>
        <Row justify="space-between">
          {mappedTertiaryMenu.length > 0 && (
            <Col xs={24} lg={6}>
              <SectionNavComponent
                navItems={mappedTertiaryMenu}
                activeItem={sectionItem}
                handleClick={handleTertiaryMenuClick}
                isMobileParent={isMobile}
              />
            </Col>
          )}
          <Col xs={mappedTertiaryMenu.length > 0 ? 18 : 24}>
            <StyledHeader>{activeNavItem.partner_type_value}</StyledHeader>
            {mappedTertiaryMenu.length > 0 ? (
              <>
                <H6>{activeSideNavItem.partner_section_value}</H6>
                <StyledPageContent
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(translate(activeSideNavItem).partner_section_description),
                  }}
                />
              </>
            ) : (
              <StyledPageContent
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(translate(activeNavItem).partner_type_description),
                }}
              />
            )}
            <Row>
              {displayPartners &&
                displayPartners.map(
                  (partner: Partner) =>
                    activeNavItem.directusId === partner.partner_type && (
                      <PartnerImageContainer
                        key={partner.directusId}
                        md={mappedTertiaryMenu.length > 0 ? 8 : 6}
                        xs={24}
                        title={partner.partner_title}
                      >
                        <StyledCard
                          onClick={() =>
                            partner.display_partner_page ? handleCardClick(partner.partner_slug) : undefined
                          }
                          bordered={false}
                          cover={<img alt={partner.partner_cta_label} src={partner.partner_logo.data.full_url} />}
                          disabled={!partner.display_partner_page}
                        />
                      </PartnerImageContainer>
                    )
                )}
            </Row>
          </Col>
        </Row>
      </PartnersContainer>
    </PublicLayout>
  );
};

export default Partners;
