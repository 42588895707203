import React from 'react';
import { Card, Col } from 'antd';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { navigateTo } from '../../../../state/actions/routing';
import theme from '../../../../theme';
import { useI18NContext } from '../../../../i18n';

const { Meta } = Card;

const HonoreeCardWrapper = styled.div`
  margin-top: 25px;
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 32px;
  padding-right: 32px;
  .ant-card-body {
    padding: 32px 0;
  }
`;

const StyledMeta = styled(Meta)`
  & .ant-card-meta-detail .ant-card-meta-title,
  & .ant-card-meta-detail .ant-card-meta-description {
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    color: ${theme.colorsBlack};
  }

  & .ant-card-meta-detail .ant-card-meta-title {
    line-height: 25px;
    font-weight: bold;
  }
`;

const Image = styled.img`
  height: 245px;
  object-fit: cover;
  && {
    border-radius: 5px;
  }
`;

const HonoreesContent = ({ data }: any) => {
  const dispatch = useDispatch();
  const { translateSlug } = useI18NContext();

  const handleCardClick = (slug: string) => {
    dispatch(navigateTo(translateSlug(slug)) as any);
  };

  return (
    data.length &&
    data.map((honoree: any) => {
      return (
        <HonoreeCardWrapper key={`wrapper-${honoree.directusId}`}>
          <HonoreeCard
            key={honoree.directusId}
            honoree={honoree}
            handleCardClick={() => handleCardClick(honoree.honoree_slug)}
          />
        </HonoreeCardWrapper>
      );
    })
  );
};

export default HonoreesContent;

type HonoreeCardProps = {
  honoree: any;
  handleCardClick: (e: any) => void;
};

export const HonoreeCard: React.FC<HonoreeCardProps> = ({ honoree, handleCardClick }) => {
  return (
    <Col key={honoree.directusId} lg={8} md={12} xs={12}>
      <StyledCard
        onClick={handleCardClick}
        bordered={false}
        cover={<Image alt={`honoree: ${honoree.honoree_name}`} src={honoree.img_file.data.full_url} />}
      >
        <StyledMeta title={honoree.honoree_name} description={honoree.honoree_position} />
      </StyledCard>
    </Col>
  );
};
