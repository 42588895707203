/* eslint-disable @typescript-eslint/no-var-requires */
let content: Record<string, any> = {};

try {
  const tenantConfig = require(`../static/tenant-config`);

  content = tenantConfig?.siteContent || {};
} catch (err) {
  console.error(err);
  throw err;
}

export const {
  programShortName,
  programLongName,
  tenantLabel = `${programShortName}`,
  copyrightLanguage,
  signInContent,
} = content;

export default content;
