import produce from 'immer';
import jwt from 'jsonwebtoken';

import { AuthAction, AuthActionTypes } from '../actions/auth';
import { IUser } from '../../types/models/auth';
import { CustomError } from '../../types/models/error';

export interface AuthState {
  readonly user: IUser | null;
  readonly token: string | null;
  readonly loading: boolean;
  readonly error: CustomError | null;
  readonly samlResponse: { entityEndpoint: string; context: string } | null;
}

export const USER_DATA_LOCAL_STORAGE_KEY = 'user';
export const MOBILE_TOKEN_LOCAL_STORAGE_KEY = 'mobileToken';

function getUserDataFromLocalStorage() {
  let userData;
  if (typeof window !== 'undefined') {
    try {
      userData = localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY);
      if (userData) {
        userData = JSON.parse(userData);
      }
    } catch (err) {
      console.error(err);
    }
  }
  return userData;
}

export function getMobileTokenFromLocalStorage() {
  let token;
  if (typeof window !== 'undefined') {
    try {
      token = localStorage.getItem(MOBILE_TOKEN_LOCAL_STORAGE_KEY);
      if (token) {
        token = JSON.parse(token);
      }
    } catch (err) {
      console.error(err);
    }
  }
  return token;
}

function decodeToken(token: string) {
  return jwt.decode(token);
}

export function getInitialState(): AuthState {
  const userData = getUserDataFromLocalStorage();
  let { token = null } = userData || {};
  let user = null;
  let error;
  try {
    if (token) {
      user = decodeToken(token);
    }
  } catch (err) {
    token = null;
    error = err;
  }
  return {
    user: user as IUser,
    token,
    loading: false,
    error: error as CustomError,
    samlResponse: null,
  };
}

const initialState: AuthState = getInitialState();

export default function auth(state: AuthState = initialState, action: AuthAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case AuthActionTypes.RESET_ERROR:
        draft.loading = false;
        draft.error = null;
        break;
      case AuthActionTypes.SIGN_UP:
      case AuthActionTypes.LOGIN:
        draft.loading = true;
        draft.error = null;
        break;
      case AuthActionTypes.LOGIN_SUCCESS: {
        const { token } = action.payload;
        try {
          draft.user = decodeToken(token) as IUser | null;
          draft.token = token;
          draft.error = null;
          draft.loading = false;
          // store in localStorage
          localStorage.setItem(USER_DATA_LOCAL_STORAGE_KEY, JSON.stringify({ token }));
        } catch (err: any) {
          console.error(err);
          draft.error = err;
        }
        draft.loading = false;
        break;
      }
      case AuthActionTypes.SSO_LOGIN_SUCCESS: {
        draft.samlResponse = action.payload;
        break;
      }
      case AuthActionTypes.SAVE_MOBILE_TOKEN_SUCCESS: {
        const { token } = action.payload;
        try {
          localStorage.setItem(MOBILE_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify({ token }));
        } catch (err) {
          console.error(err);
        }
        draft.loading = false;
        break;
      }
      case AuthActionTypes.SIGN_UP_SUCCESS: {
        draft.error = null;
        draft.loading = false;
        break;
      }
      case AuthActionTypes.SIGN_UP_FAILURE:
      case AuthActionTypes.LOGIN_FAILURE:
        draft.user = null;
        draft.token = null;
        draft.loading = false;
        draft.error = action.error;
        break;
      case AuthActionTypes.LOGOUT:
        draft.user = null;
        draft.token = null;
        draft.loading = false;
        draft.error = null;
        // remove from localStorage
        localStorage.removeItem(USER_DATA_LOCAL_STORAGE_KEY);
        localStorage.removeItem(MOBILE_TOKEN_LOCAL_STORAGE_KEY);
        break;
      default:
        return;
    }
  });
}
