import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { AppThunk } from '.';

import { getClassLevelsList, getEnrollmentStatusesList, getLookupList } from '../../apis/users/profile';
import { LookupOption } from '../../types/models/user-management';
import { LookupType } from '../../types/models/lookup';
import { getAllLookupsFromState } from '../../state/selectors/lookup';
import { getLookupListByType } from '../../apis/public/master-tables';
import { getInternalCohorts } from '../../apis/users/cohorts';

export enum LookupActionTypes {
  FETCH_ROLES_LOOKUP = 'FETCH_ROLES_LOOKUP',
}

export function fetchLookupAction(): AppThunk {
  return async (dispatch) => {
    dispatch({ type: LookupActionTypes.FETCH_ROLES_LOOKUP });
    try {
      const response = await getLookupList();
      dispatch({
        type: LookupActionTypes.FETCH_ROLES_LOOKUP,
        payload: response,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchClassLevelsAction(): AppThunk {
  return async (dispatch) => {
    dispatch({ type: LookupType.CLASS_LEVELS });
    try {
      const response = await getClassLevelsList();
      dispatch({
        type: LookupType.CLASS_LEVELS,
        payload: response,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchEnrollmentStatusesAction(): AppThunk {
  return async (dispatch) => {
    dispatch({ type: LookupType.ENROLLMENT_STATUSES });
    try {
      const response = await getEnrollmentStatusesList();
      dispatch({
        type: LookupType.ENROLLMENT_STATUSES,
        payload: response,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const fetchCohortsLookupAction = (): AppThunk => async (dispatch) => {
  try {
    const response = await getInternalCohorts({ limit: 100, offset: 0 });
    dispatch({
      type: LookupType.COHORTS,
      payload: response.data.map((item: any) => ({ label: item.cohortName, value: item.id })),
    });
  } catch (error) {
    console.error(error);
  }
};

export function saveLookup(lookup: LookupType, data: LookupOption[]): AppThunk {
  return (dispatch) => {
    dispatch({
      type: lookup,
      payload: data,
    });
  };
}

export const useLookupByType = (lookupList: LookupType[], defaultValue = false) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(defaultValue);
  const memoryLookups = useSelector(getAllLookupsFromState);

  useEffect(() => {
    const fetchLookups = async () => {
      try {
        setIsLoading(true);
        await Promise.all(
          lookupList.map(async (lookup) => {
            if (!memoryLookups[lookup]?.length) {
              const response = await getLookupListByType(lookup);
              dispatch(saveLookup(lookup, response) as any);
            }
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLookups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isLoading };
};

export const useLookupByTypePlain = async (lookupList: LookupType[]) => {
  const dispatches = [] as any[];
  await Promise.all(
    lookupList.map(async (lookup) => {
      const response = await getLookupListByType(lookup);
      dispatches.push(saveLookup(lookup, response));
    })
  );
  return dispatches;
};
