import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { filter } from 'lodash';
import { useDispatch } from 'react-redux';

import { getRealNodes } from '../utils/contentFilter';
import AdditionalComponent from '../components/home/additional';
import BottomPanel from '../components/home/bottom-panel';
import { FeaturedEventsBottomPanelData } from '../types/cms/models/featured-events';
import FeaturedEventsComponent from '../components/home/featured-events';
import HeroComponent from '../components/home/hero';
import { ISectionData, ISectionDataNoTranslation } from '../types/cms/models/home';
import PartnerComponent from '../components/home/partner';
import PublicLayout from '../layout/public-layout';
import SectionComponent from '../components/home/section';
import TestimonialComponent from '../components/home/testimonial';
import { navigateTo } from '../state/actions/routing';

// The query used to provide the page data
export const pageQuery = graphql`
  query HomeQuery {
    heroSections: allDirectusHomePageSection(
      sort: { order: ASC, fields: sort }
      filter: { home_section_type: { section_type_name: { eq: "hero" } } }
    ) {
      nodes {
        sort
        home_section_type {
          section_type_name
          section_type_value
        }
        home_section_image {
          data {
            full_url
          }
        }
        home_section_tagline
        home_section_description
        home_section_title
        home_section_cta_text
        home_section_cta_link
        directusId
      }
    }

    sections: allDirectusHomePageSection(
      sort: { order: ASC, fields: sort }
      filter: { home_section_type: { section_type_name: { nin: ["hero", "additional", "card", "partner"] } } }
    ) {
      nodes {
        directusId
        sort
        home_section_type {
          section_type_name
          section_type_value
        }
        home_section_image {
          data {
            full_url
          }
        }
        home_section_tagline
        home_section_description
        home_section_title
        home_section_cta_text
        home_section_cta_link
      }
    }

    additionalSections: allDirectusHomePageSection(
      sort: { order: ASC, fields: sort }
      filter: { home_section_type: { section_type_name: { in: ["additional", "card"] } } }
    ) {
      nodes {
        sort
        home_section_type {
          section_type_name
          section_type_value
        }
        home_section_image {
          data {
            full_url
          }
        }
        translation {
          home_section_tagline
          home_section_title
          home_section_description
          home_section_cta_text
        }
        home_section_cta_link
        directusId
      }
    }

    partnerSections: allDirectusHomePageSection(
      sort: { order: ASC, fields: sort }
      filter: { home_section_type: { section_type_name: { eq: "partner" } } }
    ) {
      nodes {
        directusId
        home_section_title
        home_section_cta_text
        translation {
          home_section_title
          home_section_cta_text
        }
      }
    }

    partnerImages: allDirectusPartner(sort: { fields: [sort] }) {
      nodes {
        partner_title
        partner_slug
        ticker_row
        display_partner_page
        partner_logo {
          data {
            full_url
          }
        }
      }
    }
    featuredEvents: allDirectusFeaturedEvent {
      nodes {
        featured_events_image {
          data {
            full_url
          }
        }
        date
        featured_event_timezone {
          timezone_value
        }
        featured_events_description
        featured_events_title
        featured_events_register_button_link
        featured_events_learn_more_button_link
        translation {
          featured_events_register_button_label
          featured_events_learn_more_button_label
        }
        directusId
      }
    }
    featuredEventsBottomBanner: allDirectusFeaturedEventsBottomBanner {
      nodes {
        featured_events_bottom_image {
          data {
            full_url
          }
        }
        featured_events_bottom_description
        featured_events_bottom_title
        featured_events_bottom_donate_link
        directusId
        cta_label
      }
    }
  }
`;

const Home = ({ data }: any) => {
  // Extracting post data from props.
  const dispatch = useDispatch();
  const myRef = useRef<HTMLSpanElement>(null);

  const {
    heroSections = {},
    sections,
    additionalSections,
    partnerSections,
    partnerImages,
    featuredEvents,
    featuredEventsBottomBanner,
  } = data;

  const cards = filter(additionalSections.nodes, (node) => {
    return node.home_section_type.section_type_name === 'card';
  });

  const featuredEventItems = getRealNodes(featuredEvents);
  const featuredEventsBottomBannerItems = getRealNodes(featuredEventsBottomBanner);

  const onCtaClick = (link: string) => {
    if (link) {
      if (link.indexOf('http') === 0) {
        window.location.href = link;
        return;
      }
      dispatch(navigateTo(link) as any);
    } else {
      const top = myRef && myRef.current ? myRef.current.offsetTop : 1000;
      window.scrollTo(0, top);
    }
  };

  return (
    <PublicLayout seoTitle="Home">
      {heroSections &&
        heroSections.nodes.length > 0 &&
        heroSections.nodes.map((section: ISectionDataNoTranslation) => {
          return (
            <HeroComponent
              key={section.directusId}
              tagLine={section.home_section_tagline}
              description={section.home_section_description}
              cta={section.home_section_cta_text}
              ctaLink={section.home_section_cta_link}
              imageUrl={section.home_section_image?.data?.full_url || undefined}
              handleClick={onCtaClick}
            />
          );
        })}

      {featuredEventItems.length > 0 ? <FeaturedEventsComponent data={featuredEventItems} /> : null}

      <span ref={myRef}></span>

      {partnerSections &&
        partnerSections.nodes?.length > 0 &&
        partnerImages &&
        partnerImages?.nodes?.length > 0 &&
        partnerSections.nodes.map((partnerSection: ISectionData) => {
          return (
            <PartnerComponent
              key={partnerSection.directusId}
              handleClick={onCtaClick}
              partnerImages={partnerImages}
              title={partnerSection.translation?.home_section_title || partnerSection.home_section_title}
              ctaLabel={partnerSection.translation?.home_section_cta_text || partnerSection.home_section_cta_text}
            />
          );
        })}

      {sections &&
        sections.nodes.map((section: ISectionDataNoTranslation) => {
          if (section.home_section_type.section_type_name === 'section') {
            return (
              <SectionComponent
                key={section.directusId}
                sort={section.sort}
                tagLine={section.home_section_tagline}
                title={section.home_section_title}
                description={section.home_section_description}
                cta={section.home_section_cta_text}
                ctaLink={section.home_section_cta_link}
                imageUrl={section.home_section_image?.data?.full_url || undefined}
                handleClick={onCtaClick}
              />
            );
          } else if (section.home_section_type.section_type_name.toLowerCase() === 'testimonial') {
            return (
              <TestimonialComponent
                key={section.directusId}
                tagLine={section.home_section_tagline}
                description={section.home_section_description}
                cta={section.home_section_cta_text}
                ctaLink={section.home_section_cta_link}
                handleClick={onCtaClick}
                imageUrl={section.home_section_image?.data?.full_url || undefined}
              />
            );
          }
          return null;
        })}
      {additionalSections &&
        additionalSections.nodes.map((section: ISectionData) => {
          if (section.home_section_type.section_type_name === 'additional') {
            return (
              <AdditionalComponent
                key={section.directusId}
                title={section.translation.home_section_title}
                description={section.translation.home_section_description}
                cards={cards}
                handleClick={onCtaClick}
              />
            );
          }

          return null;
        })}

      {featuredEventsBottomBannerItems.length > 0 &&
        featuredEventsBottomBannerItems.map((item: FeaturedEventsBottomPanelData) => (
          <BottomPanel
            key={item.directusId}
            link={item.featured_events_bottom_donate_link}
            image={item.featured_events_bottom_image.data.full_url}
            title={item.featured_events_bottom_title}
            description={item.featured_events_bottom_description}
            ctaLabel={item.cta_label}
            handleClick={onCtaClick}
          />
        ))}
    </PublicLayout>
  );
};

export default Home;
