import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from 'react-query';
import { Link } from 'gatsby';
import { List, Breadcrumb, Row, Col } from 'antd';
import { Progress } from 'antd5';
import NavigateNext from '@material-ui/icons/NavigateNext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styled from '@emotion/styled';

import { StyledContainerTracks, StyledHeaderContainer, StyledContainerContent } from './styles';
import { MenuItemsTabIds } from './university-tracks-list';

import theme from '../../../theme';
import { Button, Alert, Modal } from '../../../components';
import { NavigationWrapper } from '../../../components/common/page-header';
import { getTrack, getTrackImageURL, startTrack, enrollInCourse } from '../../../apis/users/hsf-university';
import Loader from '../../../components/common/loader';
import { programShortName, tenantLabel } from '../../../siteContent';
import { ITrackDetails } from '../../../types/models/hsf-university';
import { getTrackProgress } from '../../../utils/hsf-university';

const StyledHeaderContent = styled(Row)`
  margin: 16px 0 40px;
  padding-bottom: 30px;

  .course-img {
    max-width: 348px;
    max-height: 198px;
    width: auto;
    height: auto;
  }

  .track-name {
    margin: 12px 0 16px;
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
    color: ${theme.colorsBlack};
  }

  .course-number {
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colorsBlack};
  }
`;

const Percent = styled.p`
  margin: 0 0 4px 0;
  padding: 0;
  color: ${theme.colorsBlack};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 19px;
  text-align: right;
`;

const StyledProgress = styled(Progress)`
  margin-bottom: 8px;
`;

const StyledTextContainer = styled(Row)`
  background-color: ${theme.colorsWhite};
  margin-top: 20px;

  .title {
    width: 100%;
    padding: 24px 40px;
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    color: ${theme.colorsBlack};
    border-bottom: 1px solid ${theme.colorsMediumGrey};
  }

  .description {
    margin: 24px 40px;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colorsBlack};
    white-space: pre-wrap;
  }

  ul > li {
    margin: 10px 40px;
    border-bottom: 1px solid ${theme.colorsMediumGrey};
  }

  h4 > div {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: ${theme.colorsBlack};
  }
`;

const StyledBlockTextContainer = styled(StyledTextContainer)`
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  zoom: 1;
  display: block !important;
  box-sizing: border-box;
`;

const StyledCourseRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;

    svg {
      margin-left: 12px;
      fill: ${theme.colorsPrimaryBlue};
    }
  }
`;

const StyledButton = styled(Button)`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 36px;
    font-size: 14px;
    border-width: 2px;
  }
`;

function UniversityTrackDetails({ id }: RouteComponentProps<{ id: string }>) {
  const {
    data: trackData,
    isLoading,
    error,
  } = useQuery([getTrack.QUERY_KEY, { id }], ({ queryKey }: any) => getTrack(queryKey[1].id), { enabled: !!id });

  const [track, setTrack] = useState<ITrackDetails>();
  const [isTrackUpdating, setIsTrackUpdating] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if (trackData) {
      setTrack(trackData as ITrackDetails);
    }
  }, [trackData]);

  useEffect(() => {
    const getFileUrl = async (imgId: string) => {
      const file = await getTrackImageURL(imgId);
      if (file) {
        setImageUrl(file);
      }
    };
    if (track?.imageId) {
      getFileUrl(track.imageId);
    }
  }, [track]);

  const updatePage = async (modalText: string) => {
    if (id) {
      setIsTrackUpdating(true);
      try {
        const updateTrack = await getTrack(id);
        setTrack(updateTrack);
      } catch (err) {
        Modal.error({ title: modalText, content: err });
      } finally {
        setIsTrackUpdating(false);
      }
    }
  };

  const handleStartTrack = async (trackId: string) => {
    try {
      await startTrack(trackId);
    } catch (error: any) {
      Modal.error({ title: 'Start Track', content: error.message });
    } finally {
      updatePage('Start Track');
    }
  };

  const onHandleGoToCourse = (url: string) => {
    window.open(url, '_blank');
  };

  const onHandleEnrollCourse = async (courseId: string) => {
    try {
      await enrollInCourse(courseId);
    } catch (error: any) {
      Modal.error({ title: 'Enroll Course', content: error.message });
    } finally {
      updatePage('Enroll Course');
    }
  };

  const renderContent = () => {
    if (isLoading || isTrackUpdating) {
      return <Loader />;
    }
    if (error) {
      return <Alert type="error" message={(error as any).message} />;
    }

    const getPathFromType = (type?: string): string => {
      let path = '/myhsf/tracks/recommended';

      if (type === MenuItemsTabIds.IN_PROGRESS) {
        path = '/myhsf/tracks/inprogress';
      } else if (type === MenuItemsTabIds.COMPLETED) {
        path = '/myhsf/tracks/completed';
      }

      return path;
    };

    const generateBreadcrumb = (name?: string) => {
      return [
        <Link key="1" to="/myhsf/dashboard">
          {tenantLabel} Dashboard
        </Link>,
        <Link key="2" to={getPathFromType(track?.listType)}>
          {programShortName} University
        </Link>,
        name && `${name}`,
      ];
    };

    return (
      <>
        <StyledHeaderContainer>
          <NavigationWrapper>
            <Breadcrumb separator={<NavigateNext />}>
              {track &&
                track.listType &&
                generateBreadcrumb(track?.name).map((item, index) => (
                  <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                ))}
            </Breadcrumb>
          </NavigationWrapper>
          <StyledHeaderContent>
            <Col xs={24} sm={24} md={24} lg={8}>
              <img className="course-img" src={imageUrl} alt={track?.name} />
            </Col>
            <Col xs={23} sm={15} md={18} lg={12}>
              <div className="track-name">{track?.name}</div>
              <div className="course-number">
                {track?.courses
                  ? `${track?.courses.length} ${track?.courses.length === 1 ? 'course' : 'courses'}`
                  : null}
              </div>
              <Percent>{track?.courses ? getTrackProgress(track?.courses) : 0}% complete</Percent>
              <StyledProgress
                percent={track?.courses ? getTrackProgress(track?.courses) : 0}
                size={['100%', 11]}
                strokeColor={theme.colorsPrimaryBlue}
                showInfo={false}
              />
            </Col>
            <Col xs={3} sm={3} md={3} lg={3} offset={1}>
              {track?.listType === 'recommended' && (
                <Button type="primary-blue" onClick={() => handleStartTrack(track.id)}>
                  Start Track
                </Button>
              )}
            </Col>
          </StyledHeaderContent>
        </StyledHeaderContainer>

        <StyledContainerContent>
          <StyledTextContainer>
            <div className="title">About this Track</div>
            <div className="description">{track?.description}</div>
          </StyledTextContainer>

          <StyledBlockTextContainer>
            <div className="title">Courses in this Track</div>
            <List
              size="large"
              dataSource={track && track.courses ? track.courses : []}
              renderItem={(item: any) => (
                <List.Item>
                  <Row style={{ width: '100%' }}>
                    <List.Item.Meta
                      title={
                        <StyledCourseRow>
                          <div>
                            {item.name}
                            {item.completed && <CheckCircleIcon />}
                          </div>
                          {(track?.listType === 'inProgress' || track?.listType === 'completed') && (
                            <>
                              {item.enrolled ? (
                                <StyledButton type="primary-blue-outline" onClick={() => onHandleGoToCourse(item?.url)}>
                                  Go To Course
                                </StyledButton>
                              ) : (
                                <StyledButton type="primary-blue" onClick={() => onHandleEnrollCourse(item?.id)}>
                                  Enroll
                                </StyledButton>
                              )}
                            </>
                          )}
                        </StyledCourseRow>
                      }
                    />
                  </Row>
                </List.Item>
              )}
            />
          </StyledBlockTextContainer>
        </StyledContainerContent>
      </>
    );
  };

  return <StyledContainerTracks>{renderContent()}</StyledContainerTracks>;
}

export default UniversityTrackDetails;
