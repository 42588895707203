import axios from 'axios';

import { stringifyScholarshipQueryFilter } from './schollarship-finder-utils';

import { formatCurrencyString } from '../../utils/common';
import { CMS_URL } from '../../constants';
import responseParser from '../response-parser';
import { createError } from '../error';
import { ScholarshipCMSType, ScholarshipFinderType } from '../../types/models/scholarship-finder';
import { Scholarship } from '../../types/models/scholarship';
import { CustomAxiosError } from '../../types/models/error';

export interface IScholarshipFinderQuery extends Partial<ScholarshipCMSType> {
  offset?: number;
  searchText?: string;
}

function parseCMSScholarship(data: ScholarshipCMSType): Scholarship {
  return {
    id: data.id,
    name: data.scholarship_name,
    description: data.scholarship_description,
    url: data.url,
    image: data.image,
    gender: data.gender,
    race: data.race,
    hispanic: data.hispanic,
    geographicEligibility: data.geographic_eligibility,
    undergraduateLevel: data.undergraduate_level?.replace(/,[ ]*/g, ', '),
    fieldOfStudy: data.field_of_study?.replace(/,[ ]*/g, ', '),
    householdIncome: formatCurrencyString(data.family_income),
    citizenship: data.citizenship?.replace(/,[ ]*/g, ', '),
    studyAbroadScholarshipsFellowships: data.study_abroad_scholarships_fellowships,
    amountOfAward: formatCurrencyString(data.amount_of_award),
    graduateLevel: data.graduate_level?.replace(/,[ ]*/g, ', '),
  };
}
export async function getScholarshipFinderToPages(
  filterQuery: IScholarshipFinderQuery
): Promise<ScholarshipFinderType> {
  const meta = `filter_count,total_count,result_count`;
  const sort = 'scholarship_name';

  const url = `${CMS_URL}/items/scholarships?${stringifyScholarshipQueryFilter(filterQuery)}&sort=${sort}&meta=${meta}`;

  try {
    const response = await axios.get(url);
    const scholarships: ScholarshipCMSType[] = responseParser(response);
    return {
      scholarships: scholarships.map(parseCMSScholarship),
      totalCount: response.data.meta.filter_count,
    };
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}
getScholarshipFinderToPages.QUERY_KEY = 'getScholarshipFinderToPages';

export async function getScholarshipFinderFilters({
  searchTerm,
  page,
}: {
  searchTerm: string;
  page: number;
}): Promise<Array<Scholarship>> {
  // console.log(`Search: ${searchTerm}   --  Page: ${page}`);
  const DEFAULT_PAGE_SIZE = 20;
  const params: { [key: string]: any } = {
    fields: 'id,scholarship_name',
    sort: 'scholarship_name',
    offset: Number(page || 0) * DEFAULT_PAGE_SIZE,
    limit: DEFAULT_PAGE_SIZE,
  };
  if (searchTerm) {
    params['filter[scholarship_name][like]'] = `${searchTerm}`;
  }
  try {
    const url = `${CMS_URL}/items/scholarships`;
    const response = await axios.get(url, { params });
    const scholarships: ScholarshipCMSType[] = responseParser(response);
    return scholarships.map(parseCMSScholarship);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

getScholarshipFinderFilters.QUERY_KEY = 'getCollegeFinderFilters';

export async function getScholarshipDetail(id: string): Promise<Scholarship> {
  try {
    const url = `${CMS_URL}/items/scholarships/${id}`;
    const response = await axios.get(url);
    const scholarship = responseParser(response);
    return parseCMSScholarship(scholarship);
  } catch (error) {
    throw createError(error as CustomAxiosError);
  }
}
getScholarshipDetail.QUERY_KEY = 'getScholarshipDetail';
