import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import theme from '../../../../theme';
import { getCoach } from '../../../../apis/users/coaches-match';
import Loader from '../../../../components/common/loader';
import SEO from '../../../../components/seo';
import PageHeader from '../../../../components/common/page-header';
import { StyledMessage } from '../../scholarship/common/styles';
import UserContent from '../../../../components/common/user-content';
import { Skills, formatUniversity } from '../../mentorship/common';
import { UserContentLayoutType } from '../../../../types/layout';
import { tenantLabel } from '../../../../siteContent';
import UserBioHeader from '../../../../components/user/user-bio/user-bio-header';
import { Button } from '../../../../components';
import UserBioContentCard from '../../../../components/user/user-bio/user-bio-content-card';
import { openChat, setIsLoadingChat } from '../../../../state/actions/message';
import { getIsLoadingChat, getUserIdForChat } from '../../../../state/selectors/message';

import '../../../../types/emotion.d.ts';

type CoachBioProps = {
  userId: string;
};

const CoachSkillRow = styled.div`
  display: list-item;
  list-style: disc inside none;
`;

const UserContentCss = css`
  padding-top: 30px;
  background-color: ${theme.colorsLightestGrey};
`;

const CoachBio: React.FC<RouteComponentProps<CoachBioProps>> = ({ userId }) => {
  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  const {
    data: coach,
    isLoading,
    error,
  } = useQuery([getCoach.QUERY_KEY, { userId: userId as string }], ({ queryKey }: any) => getCoach(queryKey[1].userId));

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbs: Array<React.ReactNode | string> = [
    <Link key="1" to="/myhsf">
      {tenantLabel} Dashboard
    </Link>,
    <Link key="2" to="/myhsf/my-coaches">
      {tenantLabel} Coaches
    </Link>,
    `${coach?.firstName} ${coach?.lastName}`,
  ];

  const coachSkills = Skills.filter((skill) => coach?.skills?.includes(skill.value)).map((skill) => {
    return <CoachSkillRow key={skill.value}>{skill.label}</CoachSkillRow>;
  });

  const sendMessage = (userId: string | undefined) => {
    if (userId) {
      dispatch(setIsLoadingChat(true));
      dispatch(openChat(userId));
    }
  };

  const location = [coach?.city, coach?.state].filter((v) => v !== null && v !== undefined && v !== '').join(', ');

  const education =
    coach?.education &&
    Object.keys(coach?.education).reduce(
      (res, degree: string) => {
        coach?.education[degree].forEach((d: { major: string; school: string }) => {
          res.majors.push(d.major);
          res.schools.push(formatUniversity(d.school));
        });

        return res as { majors: string[]; schools: string[] };
      },
      { majors: [] as string[], schools: [] as string[] }
    );

  const coachInfo = [
    {
      label: 'Location',
      value: location,
    },
    {
      label: 'Job Type',
      value: coach?.currentCompanyIndustries?.join(', '),
    },
    {
      label: 'Professional Experience',
      value: coach?.employmentExperience,
    },
    {
      label: 'Education',
      value: education?.schools.join(', '),
    },
    {
      label: 'College Major',
      value: education?.majors.join(', '),
    },
  ];

  return (
    <>
      <SEO title="Coaches" />
      <PageHeader title={''} breadcrumb={breadcrumbs} noShadow />
      {!error && (
        <>
          <UserBioHeader
            title={`${coach?.firstName} ${coach?.lastName}`}
            image={coach?.image}
            subtitle={coach?.jobTitle}
            userInfoRows={coachInfo}
            button={
              <Button
                key={'send-message'}
                type={'primary-blue'}
                onClick={() => sendMessage(coach?.id)}
                block
                loading={isLoadingChat && chatUserId === coach?.id}
              >
                Send Message
              </Button>
            }
          />
          <UserContent type={UserContentLayoutType.DASHBOARD} css={UserContentCss}>
            <UserBioContentCard title={'Why I Want To Be a Coach'} content={coach?.coachshipReason} />
            {coachSkills.length > 0 && <UserBioContentCard title={'What I Can Help You With'} content={coachSkills} />}
          </UserContent>
        </>
      )}
      {error && <StyledMessage>{(error as any).message}</StyledMessage>}
    </>
  );
};

export default CoachBio;
