import { BreakPoints, BreakPointsType } from './types/layout';
import { BroadcastMessageStatus, BroadcastStatus, BroadcastType } from './types/models/broadcast-center';
import {
  CampaignLevel,
  CampaignType,
  EventLocation,
  EventPrivacy,
  EventRole,
  ProgramType,
} from './types/models/program-management';
import { CategoryType, MessageType } from './types/models/message-center';
import {
  CurrentEnrollmentStatusType,
  EnrollmentVerificationType,
  GPAScaleType,
  GPAWeightType,
  HiddenFieldType,
  HousingStatusType,
  RecommenderType,
} from './types/form';
import { Ethnicity, ReasonsToRemove, StudentType } from './types/models/user-management';
import { DocumentType } from './types/models/document';
import { EmailNotificationsType } from './types/models/account-settings';
import { RoleStatus } from './types/models/roles-permissions';
import { RoleType } from './types/models/auth';
import { programShortName } from './siteContent';
import theme from './theme';

export const TENANT = process.env.GATSBY_TENANT;
export const TENANT_ID = process.env.GATSBY_TENANT_ID;
export const TENANT_NAME = process.env.GATSBY_TENANT_NAME;
export const WEBSOCKET_URL = process.env.GATSBY_WEBSOCKET_URL || '';
export const API_URL = process.env.GATSBY_API_URL || 'http://localhost:3000';
export const FP_API_URL = process.env.GATSBY_FP_API_URL || '';
export const MOCK_URL = process.env.GATSBY_MOCK_URL || 'https://30715024-0e32-4fdd-b5ca-74508566219f.mock.pstmn.io';
export const CMS_URL = process.env.GATSBY_CMS_API_URL || 'http://ec2-54-176-84-114.us-west-1.compute.amazonaws.com/hsf';
export const ADDTHIS_KEY_SOCIAL_MEDIA = process.env.GATSBY_ADDTHIS_KEY_SOCIAL_MEDIA || 'ra-54b56e9e274413fb';
export const CANVAS_URL = 'http://3.101.67.76';
export const ARTICLE = process.env.GATSBY_ARTICLE || 'a';
// Feature Flags
export const ENABLE_STAGE_INVITATION = (process.env.GATSBY_ENABLE_STAGE_INVITATION || 'false') === 'true';

// TODO: point to CloudFront URL
// TODO: update Github action to populate env specific `GATSBY_ASSETS_URL` from secret once the spec is finalized
export const ASSETS_URL = process.env.GATSBY_ASSETS_URL || 'https://dev-hsf-assets.s3-us-west-1.amazonaws.com';
export const DEFAULT_MODAL_WIDTH = 600;
export const LARGE_MODAL_WIDTH = 1000;
export const CAPTCHA_SITE_KEY = process.env.CAPTCHA_SITE_KEY || '6Ld7-FkUAAAAALc5miMTXcFwu-guEILhwACNvait';

export const EVENTBRITE_SIGN_IN_PAGE = 'https://www.eventbrite.com/signin/';

// enable for permission functionality
export const PERMISSIONS_ENABLED = process.env.GATSBY_PERMISSIONS_ENABLED === 'true';

// `screen-xl` set from `global-theme.js` to reflect to Ant Design's breakpoint system
const MAX_PAGE_CONTENT_WIDTH = parseInt(theme.screenXl, 10);
export const LAYOUT_CONFIG: {
  MAX_PAGE_WIDTH: number;
  MAX_PAGE_CONTENT_WIDTH: number;
  BREAK_POINTS: BreakPointsType;
} = {
  MAX_PAGE_WIDTH: 1440,
  MAX_PAGE_CONTENT_WIDTH,
  BREAK_POINTS: {
    [BreakPoints.XS]: 0,
    [BreakPoints.SM]: parseInt(theme.screenSm, 10),
    [BreakPoints.MD]: parseInt(theme.screenMd, 10),
    [BreakPoints.LG]: parseInt(theme.screenLg, 10),
    [BreakPoints.XL]: MAX_PAGE_CONTENT_WIDTH,
    [BreakPoints.XXL]: parseInt(theme.screenXxl, 10),
  },
};

export const MAX_TABLE_HEIGHT = '66vh';

export const ROLE_TYPE_LABELS: Record<RoleType, string> = {
  [RoleType.STUDENT]: 'Student',
  [RoleType.SCHOLAR]: 'Scholar',
  [RoleType.SUPPORTER]: `${programShortName} Supporter`,
  [RoleType.READER]: 'Reader',
  [RoleType.INTERVIEWER]: 'Interviewer',
  [RoleType.RECOMMENDER]: 'Recommender',
  [RoleType.COUNSELOR]: 'Counselor',
  [RoleType.EDUCATOR]: 'Educator',
  [RoleType.ADMIN]: 'Admin',
  [RoleType.DISPATCHER_ADMIN]: 'Dispatcher Admin',
  [RoleType.PARENT]: 'Parent',
  [RoleType.TECHNICIAN_ADMIN]: 'Technician Admin',
  [RoleType.SYSTEM_ADMIN]: 'System Admin',
  [RoleType.HCPI_QUALIFIED_USER]: 'HCPI Qualified User',
  [RoleType.ALUMNI_SELF_IDENTIFIED]: 'Alumni Self Identified',
  [RoleType.ALUMNI_VERIFIED]: 'Alumni Verified',
  [RoleType.FAO]: 'FAO',
  [RoleType.DONOR]: 'Donor',
  [RoleType.MENTOR]: 'Mentor',
  [RoleType.MENTEE]: 'Mentee',
  [RoleType.COACH]: 'Coach',
  [RoleType.COACHEE]: 'Coachee',
  [RoleType.ADVISORY_COUNCIL_MEMBER]: 'Advisory Council Member',
  [RoleType.BOARD_OF_DIRECTOR]: 'Board Of Director',
  [RoleType.SPONSOR]: 'Sponsor',
  [RoleType.MANAGER_ADMIN]: 'Manager',
  [RoleType.READER_MANAGER]: 'Reader Management',
  [RoleType.INTERVIEWER_MANAGER]: 'Interview Management',
  [RoleType.PROGRAM_MENTOR]: 'Program Mentor',
  [RoleType.PROGRAM_MENTEE]: 'Program Mentee',
  [RoleType.ALC_SELF_IDENTIFIED]: 'ALC Self Identified',
  [RoleType.LEADER_VERIFIED]: 'Leader Verified',
};

export const PROGRAM_TYPE_LABELS: Record<ProgramType, string> = {
  [ProgramType.SCHOLARSHIP]: 'Scholarship',
  [ProgramType.CONFERENCE]: 'Conference',
  [ProgramType.SURVEY]: 'Survey',
  [ProgramType.EVENT]: 'Event',
  [ProgramType.PUBLIC_EVENT]: 'Public Event',
  [ProgramType.PRIVATE_EVENT]: 'Private Event',
  [ProgramType.JOB_CAMPAIGN]: 'Job Campaign',
  [ProgramType.VIRTUAL_CAREER_FAIR]: 'Virtual Career Fair',
};

export const PROGRAM_EVENT_PRIVACY_LABELS: Record<EventPrivacy, string> = {
  [EventPrivacy.PUBLIC_EVENT]: 'Public',
  [EventPrivacy.PRIVATE_EVENT]: 'Private',
};

export const PROGRAM_EVENT_LOCATIONS_LABELS: Record<EventLocation, string> = {
  [EventLocation.VENUE]: 'Venue',
  [EventLocation.ONLINE]: 'Online',
  [ProgramType.JOB_CAMPAIGN]: 'Job Campaign',
  [ProgramType.VIRTUAL_CAREER_FAIR]: 'Virtual Career Fair',
};

export const EVENT_ROLE_LABELS_FOR_APPLICANTS = new Map<EventRole, string>([
  [EventRole.PARTICIPANT, 'Participant'],
  [EventRole.VOLUNTEER, 'Volunteer'],
  [EventRole.MENTOR, 'Mentor'],
]);

export const USER_ROLE_LABELS = new Map<RoleType, string>([
  //
  [RoleType.SCHOLAR, ROLE_TYPE_LABELS[RoleType.SCHOLAR]],
]);

export const JOB_CAMPAIGN_TYPE_LABELS: Record<CampaignType, string> = {
  [CampaignType.INTERNSHIP_OPPORTUNITIES]: 'Internship Opportunities',
  [CampaignType.JOB_OPPORTUNITIES]: 'Job Opportunities',
  [CampaignType.WEBINAR]: 'Webinar',
  [CampaignType.OTHER]: 'Other',
};

export const JOB_CAMPAIGN_LEVEL_LABELS: Record<CampaignLevel, string> = {
  [CampaignLevel.CAMPAIGNS]: 'Campaigns',
  [CampaignLevel.NEWSLETTERS]: 'Newsletters',
  [CampaignLevel.QUALIFIED_CANDIDATES]: 'Qualified Candidates',
  [CampaignLevel.RECRUITMENT_SEARCHES]: 'Recruitment Searches',
};

export const RECOMMENDER_TYPE_LABELS: Record<RecommenderType, string> = {
  [RecommenderType.Clergy]: 'Clergy',
  [RecommenderType.Coach]: 'Coach',
  [RecommenderType.Colleague]: 'Colleague',
  [RecommenderType.Counselor]: 'Counselor',
  [RecommenderType.ElectedOfficial]: 'Elected Official',
  [RecommenderType.Manager]: 'Manager',
  [RecommenderType.Mentor]: 'Mentor',
  [RecommenderType.Principal]: 'Principal',
  [RecommenderType.Professor]: 'Professor',
  [RecommenderType.ResearchThesisAdvisor]: 'Research/Thesis Advisor',
  [RecommenderType.SchoolAdministrator]: 'School Administrator',
  [RecommenderType.Supervisor]: 'Supervisor',
  [RecommenderType.Teacher]: 'Teacher',
  [RecommenderType.Tutor]: 'Tutor',
};

export const ENROLLMENT_VERIFICATION_TYPE_LABELS: Record<EnrollmentVerificationType, string> = {
  [EnrollmentVerificationType.HSFForm]: `${programShortName} Form`,
  [EnrollmentVerificationType.InstitutionalVerificationForm]: 'Institutional Verification Form',
  [EnrollmentVerificationType.NationalStudentClearingHouse]: 'National Student Clearing House',
};

export const CURRENT_ENROLLMENT_STATUS_TYPE_LABELS: Record<CurrentEnrollmentStatusType, string> = {
  [CurrentEnrollmentStatusType.FullTime]: 'Full-time',
  [CurrentEnrollmentStatusType.ThreeQuarterTime]: 'Three-quarter time',
  [CurrentEnrollmentStatusType.HalfTime]: 'Half-time',
  [CurrentEnrollmentStatusType.LessThanHalfTime]: 'Less than half-time',
  [CurrentEnrollmentStatusType.Enrolled]: 'Enrolled',
  [CurrentEnrollmentStatusType.LeaveOfAbsence]: 'Leave of absence',
  [CurrentEnrollmentStatusType.Withdrawn]: 'Withdrawn',
  [CurrentEnrollmentStatusType.Deceased]: 'Deceased',
};

export const GPA_SCALE_TYPE_LABELS: Record<GPAScaleType, string> = {
  [GPAScaleType.Four]: '4.0',
  [GPAScaleType.Five]: '5.0',
  [GPAScaleType.Six]: '6.0',
  [GPAScaleType.Seven]: '7.0',
  [GPAScaleType.Ten]: '10.0',
  [GPAScaleType.Hundread]: '100.0',
  [GPAScaleType.Written]: 'Written Evaluation',
  [GPAScaleType.Other]: 'Other Scale',
};

export const GPA_WEIGHT_TYPE_LABELS: Record<GPAWeightType, string> = {
  [GPAWeightType.Weighted]: 'Weighted',
  [GPAWeightType.Unweighted]: 'Unweighted',
  [GPAWeightType.NotApplicable]: 'Not Applicable',
};

export const MESSAGE_TYPE_LABELS: Record<MessageType | string, string> = {
  [MessageType.GENERAL_QUESTION]: 'General Question',
  [MessageType.APPLICATION_STATUS]: 'Application Status',
  [MessageType.APPLICATION_UPDATE]: 'Application Update',
  [MessageType.ALUMNI_QUESTION]: 'Alumni Question',
  [MessageType.CAREER_QUESTION]: 'Career Question',
  [MessageType.DISBURSEMENT_QUESTION]: 'Disbursement Question',
  [MessageType.DOCUMENT_QUESTION]: 'Document Question',
  [MessageType.DONATION_QUESTION]: 'Donation Question',
  [MessageType.ELIGIBILITY_QUESTION]: 'Eligibility Question',
  [MessageType.RECOMMENDER_QUESTION]: 'Recommender Question',
  [MessageType.SCHOLAR_CONFERENCES]: 'Scholar Conferences',
  [MessageType.SCHOLARSHIP_READ_QUESTION]: 'Scholarship Read Question',
  [MessageType.TECHNICAL_CONCERN]: 'Technical Concern',
  [MessageType.VOLUNTEER_QUESTION]: 'Volunteer Question',
  [MessageType.YOUTH_LEADERSHIP_INSTITUTE]: 'Youth Leadership Institute',
};

export const MESSAGE_CATEGORY_TYPE_LABELS: Record<CategoryType, string> = {
  [CategoryType.ALUMNI]: 'Alumni',
  [CategoryType.CAREER_SERVICES]: 'Career Services',
  [CategoryType.COLLEGE_CAMP]: 'College Camp/College 101',
  [CategoryType.DONATIONS]: 'Donations',
  [CategoryType.DELETE_ACCOUNT]: 'Delete Account',
  [CategoryType.FINANCIAL_AID]: 'Financial Aid',
  [CategoryType.FUNDRAISERS]: 'Fundraisers',
  [CategoryType.HUMAN_RESOURCES]: 'Human Resources',
  [CategoryType.HSF_ADVISORY_COUNCILS]: `${programShortName} Advisory Councils`,
  [CategoryType.HSF_CONFERENCES]: `${programShortName} Conferences`,
  [CategoryType.INFORMATION_TECHNOLOGY]: 'Information Technology',
  [CategoryType.INTERVIEWERS]: 'Interviewers',
  [CategoryType.PARTNERSHIPS]: 'Partnerships',
  [CategoryType.RECOMMENDERS]: 'Recommenders',
  [CategoryType.SCHOLAR_ADVOCATES]: 'Scholar Advocates',
  [CategoryType.SCHOLAR_CELEBRATIONS]: 'Scholar Celebrations',
  [CategoryType.SCHOLARSHIPS]: 'Scholarships',
  [CategoryType.VOLUNTEER]: 'Volunteer',
  [CategoryType.YLI]: 'YLI',
  [CategoryType.YLS]: 'YLS',
};

export const DOCUMENT_TYPE_LABELS = new Map<DocumentType, string>([
  [DocumentType.TRANSCRIPT, 'Transcript'],
  [DocumentType.FINANCIAL_AID_AWARD_LETTER, 'Financial Aid Award Letter'],
  [DocumentType.EMPLOYMENT_AUTHORIZATION_DOCUMENT, 'Employment Authorization Document'],
  [DocumentType.STUDENT_AID_REPORT, 'Student Aid Report'],
  [DocumentType.THANK_YOU_LETTER_VIDEO, 'Thank You Letter/Video'],
  [DocumentType.ENROLLMENT_VERIFICATION, 'Enrollment Verification'],
  [DocumentType.HEADSHOT, 'Headshot'],
  [DocumentType.RESUME, 'Resume'],
  [DocumentType.SAT, 'SAT'],
  [DocumentType.ACT, 'ACT'],
  [DocumentType.IB_EXAM, 'IB Score Report'],
  [DocumentType.AP_EXAM, 'AP Score Report'],
]);

export const HIDDEN_FIELD_TYPE_LABELS = new Map<HiddenFieldType, string>([
  [HiddenFieldType.DEFAULT, 'Default'],
  [HiddenFieldType.EVENT_ROLE, 'Event Role'],
  [HiddenFieldType.USER_ROLE, 'User Role'],
]);

export const BROADCAST_TYPE_LABELS = new Map<BroadcastType, string>([
  [BroadcastType.CALL, 'Call'],
  [BroadcastType.EMAIL, 'Email'],
  [BroadcastType.TEXT, 'Text'],
]);

export const BROADCAST_STATUS_LABELS = new Map<BroadcastStatus, string>([
  [BroadcastStatus.DRAFT, 'Draft'],
  [BroadcastStatus.ON_HOLD, 'On Hold'],
  [BroadcastStatus.PUBLISHED, 'Published'],
  [BroadcastStatus.SCHEDULED, 'Scheduled'],
]);

export const BROADCAST_MESSAGE_STATUS_LABELS = new Map<BroadcastMessageStatus, string>([
  [BroadcastMessageStatus.PENDING, 'Pending'],
  [BroadcastMessageStatus.SENT, 'Sent'],
  [BroadcastMessageStatus.FAILED, 'Failed'],
  [BroadcastMessageStatus.DELIVERED, 'Delivered'],
  [BroadcastMessageStatus.UNDELIVERABLE, 'Undelivered'],
  [BroadcastMessageStatus.MISSING_DATA, 'Missing Data'],
]);

export const ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS_LABELS = new Map<EmailNotificationsType, string>([
  [EmailNotificationsType.OPT_OUT_NOTIFICATIONS, 'Opt Out of Notifications'],
  [EmailNotificationsType.UPON_RECEIPT, 'Upon Receipt'],
  [EmailNotificationsType.SUMMARIZED_DAILY, 'Daily system summary'],
]);

export const ROLES_STATUS_LABELS = new Map<RoleStatus, string>([
  [RoleStatus.ACTIVE, 'Active'],
  [RoleStatus.ARCHIVED, 'Archived'],
]);

export const SIGN_UP_URL = '/sign-up';
export const LOG_IN_URL = '/login';
export const SIGN_UP_CONFIRMATION_URL = '/sign-up-confirmation';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const EMAIL_CONFIRMATION_URL = '/email-confirmation';
export const PASSWORD_RESET_SUBMIT_CONFIRMATION_URL = '/password-reset-submit-confirmation';
export const PASSWORD_RESET_CONFIRMATION_URL = '/password-reset-confirmation';
export const PASSWORD_RESET_EXPIRATION_URL = '/password-reset-expiration';
export const EMAIL_ALREADY_VERIFIED_URL = '/email-already-verified';
export const SYSTEM_UPGRADE_PASSWORD_RESET_URL = '/system-upgrade-password-reset';
export const TERMS_OF_USE_URL = '/terms-of-use';
export const PRIVACY_POLICY_URL = '/privacy-policy';
export const CONTACT_US_URL = '/contact-us';
export const DASHBOARD_URL = '/myhsf/dashboard';

export const HOUSING_STATUS_TYPE_LABELS: Record<HousingStatusType, string> = {
  [HousingStatusType.OnCampus]: 'On Campus',
  [HousingStatusType.OffCampus]: 'Off Campus',
  [HousingStatusType.LivingWithParentsOrRelatives]: 'Living With Parents Or Relatives',
};

export enum MESSAGE_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const EMAIL_CONFIRMATION: Record<string, string> = {
  DISPLAY_MESSAGE: 'Verification Email Resent',
  INSTRUCTION_TEXT:
    'An email will be sent to you shortly, containing a verification link that enables you to sign in. If you ' +
    "don't receive an email from us within a couple of min, please check your spam/junk folder",
  BUTTON_TEXT: 'Go to Sign In',
};

export const PASSWORD_RESET_CONFIRMATION: Record<string, string> = {
  DISPLAY_MESSAGE: 'Your password have been reset successfully',
  BUTTON_TEXT: 'Back to Sign In',
};

export const EMAIL_VERIFICATION: Record<string, string> = {
  DISPLAY_MESSAGE: 'Your email has been verified!',
  BUTTON_TEXT: 'Continue to Sign In',
};

export const EMAIL_ALREADY_VERIFIED: Record<string, string> = {
  ERROR_MESSAGE: 'This email has already been verified',
  DISPLAY_MESSAGE: 'This email has already been verified',
  BUTTON_TEXT: 'Sign In',
};

export const EMAIL_LINK_EXPIRED: Record<string, string> = {
  DISPLAY_MESSAGE: 'Sorry, this link has expired',
  ERROR_MESSAGE: 'Sorry, this link is invalid',
  BUTTON_TEXT: 'Send Verification Link',
};

export const EMAIL_UPDATE_CONFIRMATION: Record<string, string> = {
  DISPLAY_MESSAGE: `Your Account's Email Address has been Updated!`,
  INSTRUCTION_TEXT: 'Account will now use',
  BUTTON_TEXT: 'Continue to Sign In',
};

export const EMAIL_UPDATE_ALREADY_EXISTS: Record<string, string> = {
  DISPLAY_MESSAGE: 'Sorry, an account with this email already exists',
  ERROR_MESSAGE: 'Email already exists',
};

export const EMAIL_UPDATE_LINK_EXPIRED: Record<string, string> = {
  DISPLAY_MESSAGE: 'Sorry, this link has expired',
  ERROR_MESSAGE: 'Sorry, this link is invalid',
};

export const EMAIL_UPDATE_INVALID_REQUEST: Record<string, string> = {
  DISPLAY_MESSAGE: 'Sorry, this request is invalid',
};

export const PASSWORD_RESET_LINK_EXPIRED: Record<string, string> = {
  DISPLAY_MESSAGE: 'Sorry, this link has expired',
  ERROR_MESSAGE: 'Sorry, this link is invalid',
  BUTTON_TEXT: 'Forget Password',
};

export const EMAIL_INVALID_REQUEST: Record<string, string> = {
  DISPLAY_MESSAGE: 'Invalid Request',
  BUTTON_TEXT: 'Send Verification Link',
};

export const PASSWORD_RESET_SUBMIT_CONFIRMATION: Record<string, string> = {
  DISPLAY_MESSAGE: 'Password reset submitted',
  INSTRUCTION_TEXT:
    'We have sent you an email with instructions on how to reset your password. If you do not receive an email from ' +
    'us within the next couple minutes, please check your junk/spam folder.',
  BUTTON_TEXT: 'Back to Sign In',
  LINK_TEXT: 'Resend Password Reset Email',
};

export const SIGN_UP_CONFIRMATION: Record<string, string> = {
  DISPLAY_MESSAGE: 'Thank You for creating an account!',
  INSTRUCTION_TEXT:
    'We have sent you an email containing a verification link that enables you to sign in.  If you do not receive an email from us within the next couple of minutes, please check your junk/spam folder.',
  BUTTON_TEXT: 'Go to Sign In',
  LINK_TEXT: 'Resend Verification Email',
};

export const SYSTEM_UPGRADE_PASSWORD_RESET: Record<string, string> = {
  DISPLAY_MESSAGE: 'Password reset required',
  INSTRUCTION_TEXT:
    'A password reset is required due to a recent system upgrade. \n\n' +
    'We have sent you an email with instructions on how to reset your password. If you do not receive an email from ' +
    'us within the next couple minutes, please check your junk/spam folder. \n' +
    'If you cannot locate the password reset email in your inbox or junk/spam folder, please contact us.',
  BUTTON_TEXT: 'Contact Us',
};

export const SIGN_UP_ROLES = [
  ROLE_TYPE_LABELS[RoleType.STUDENT],
  ROLE_TYPE_LABELS[RoleType.PARENT],
  ROLE_TYPE_LABELS[RoleType.EDUCATOR],
  ROLE_TYPE_LABELS[RoleType.SUPPORTER],
  ROLE_TYPE_LABELS[RoleType.ALUMNI_SELF_IDENTIFIED],
];

if (TENANT === 'hsf') {
  SIGN_UP_ROLES.push(ROLE_TYPE_LABELS[RoleType.SUPPORTER]);
  SIGN_UP_ROLES.push(ROLE_TYPE_LABELS[RoleType.ALUMNI_SELF_IDENTIFIED]);
  SIGN_UP_ROLES.push(ROLE_TYPE_LABELS[RoleType.ALC_SELF_IDENTIFIED]);
}

export const NEED_STUDENT_TYPE_ROLES = [ROLE_TYPE_LABELS[RoleType.STUDENT]];

export const PASSWORD_CONFIG: Record<string, RegExp | number | string> = {
  RULE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-z0-9A-Z]).{8,}$/,
  MIN_LENGTH: 8,
  MIN_LENGTH_ERROR: 'Your password needs to be at least 8 characters',
  RULE_ERROR:
    'Your password needs to be at least 8 characters and include at least 1 upper case letters, 1 lower case letters, 1 number (0 - 9) and 1 non-alphanumeric symbols',
  REQUIRED_ERROR: 'Password is required.',
};

export const MIN_SIGN_UP_USER_AGE = 13;

export const DEFAULT_SELECTION_TEXT = 'Select One';

export const PATH_SEPARATOR = '-|-';

export const MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MAX_CHAR_COUNT_PROGRAM_NAME = 25;

export const MAX_CHAR_COUNT_ACTIVE_STAGE = 22;

export enum HTTP_STATUS_CODE {
  UNPROCESSABLE_ENTITY = '422',
  CONFLICT = '409',
}

export const STUDENT_TYPE_LABELS: Record<StudentType, string> = {
  [StudentType.HIGH_SCHOOL_FRESHMAN]: 'High-school freshman',
  [StudentType.HIGH_SCHOOL_SOPHOMORE]: 'High-school sophomore',
  [StudentType.HIGH_SCHOOL_JUNIOR]: 'High-school junior',
  [StudentType.HIGH_SCHOOL_SENIOR]: 'High-school senior',
  [StudentType.COLLEGE_FRESHMAN]: 'College freshman',
  [StudentType.COLLEGE_SOPHOMORE]: 'College sophomore',
  [StudentType.COLLEGE_JUNIOR]: 'College junior',
  [StudentType.COLLEGE_SENIOR]: 'College senior',
  [StudentType.FIRST_YEAR_MASTERS]: 'First year masters',
  [StudentType.SECOND_YEAR_MASTERS]: 'Second year masters',
  [StudentType.THIRD_YEAR_MASTERS]: 'Third year masters',
  [StudentType.FIRST_YEAR_DOCTORATE]: 'First year doctorate',
  [StudentType.SECOND_YEAR_DOCTORATE]: 'Second year doctorate',
  [StudentType.THIRD_YEAR_DOCTORATE]: 'Third year doctorate',
  [StudentType.FOURTH_YEAR_DOCTORATE]: 'Fourth year doctorate',
  [StudentType.FIFTH_YEAR_DOCTORATE]: 'Fifth year doctorate',
  [StudentType.SIXTH_YEAR_DOCTORATE]: 'Sixth year doctorate',
  [StudentType.SEVENTH_YEAR_DOCTORATE]: 'Seventh year doctorate',
  [StudentType.EIGHTH_YEAR_DOCTORATE]: 'Eighth year doctorate',
};

export const STUDENT_TYPE_LABELS_TO_DISCERNMENT_SURVEY: string[] = [
  'High School Junior',
  'High School Sophomore',
  'High School Senior',
  'College Freshman',
  'College Sophomore',
  'College Junior',
  'College Senior',
];

export const READER_TIME_PER_ESSAY_IN_HOURS = 48;

export const ETHNICITY_LABELS: Record<Ethnicity, string> = {
  [Ethnicity.AFRICAN_AMERICAN]: 'African American',
  [Ethnicity.NATIVE_AMERICAN]: 'Native American',
  [Ethnicity.ASIAN]: 'Asian',
  [Ethnicity.HISPANIC]: 'Hispanic',
  [Ethnicity.WHITE]: 'White',
};

export const REASON_TO_REMOVE_LABELS: Record<ReasonsToRemove, string> = {
  [ReasonsToRemove.FAMILY_EMERGENCY]: 'Family Emergency',
  [ReasonsToRemove.PERSONAL_HEALTH_ISSUE]: 'Personal Health Issue',
  [ReasonsToRemove.CODE_OF_CONDUCT]: 'Code of Conduct',
  [ReasonsToRemove.INELIGIBLE]: 'Ineligible',
  [ReasonsToRemove.APPROVED_SCHEDULING_CONFLICT]: 'Approved Scheduling Conflict',
  [ReasonsToRemove.UN_APPROVED_SCHEDULING_CONFLICT]: 'Un-Approved Scheduling Conflict',
};

export const USER_IDLE_TIMEOUT_SECONDS = 900; // 60 * 15 = 15 minutes
export const ADMIN_IDLE_TIMEOUT_SECONDS = 900; // 60 * 15 = 15 minutes
export const LOGOUT_TIME_KEY = 'logout_time';

// TODO: Roman wtf
export const SCHOLAR_ID = 'd89135c7-5de8-4972-98fa-e155c40eabcf';
export const SPONSOR_ID = 'ea4403e8-9ee1-4fe5-86ea-de4cf86fe632';

// AWS Lambda input/output payload size limit in MB
export const LAMBDA_PAYLOAD_SIZE_LIMIT = 4;

export const NO_CAPTCHA_ENV = ['localhost', 'dev6'];
