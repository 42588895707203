import { Breadcrumb, Col, Row } from 'antd';
import { Collapse } from 'antd5';
import { PlusOutlined } from '@ant-design/icons';
import { Link, graphql } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import { find } from 'lodash';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { Header, InternalContainer, StyledBreadcrumb } from './basic-styles';

import HorizontalSecondaryMenu from '../components/common/horizontal-secondary-menu';
import { NavItem } from '../types/cms/models/nav-item';
import PublicLayout from '../layout/public-layout';
import SectionNavComponent from '../components/common/section-navigation';
import { navigateTo } from '../state/actions/routing';
import theme from '../theme';
import { useI18NContext } from '../i18n';
import { useResponsive } from '../hooks/use-responsive';

const MobileDisplay = `
  @media (max-width: ${theme.screenMdMax}) {
    display: none;
  }
`;

const StyledHeader = styled(Header)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Container = styled(InternalContainer)`
  padding-top: 40px;
`;

const TypeHeader = styled(Header)`
  ${MobileDisplay}
  padding-top: 0;
`;

const SectionHeader = styled(Header)`
  ${MobileDisplay}
  padding-top: 0;
  font-size: 28px;
`;

// The query used to provide the page data
export const query = graphql`
  query ($type: String!, $section: String, $language: String) {
    topNavItems: allDirectusHelpCenterType(
      filter: { page: { id: { ne: null } }, status: { eq: "published" } }
      sort: { fields: sort, order: ASC }
    ) {
      nodes {
        name
        slug
        translation(language: $language) {
          value
        }
      }
    }

    activeNavItem: directusHelpCenterType(name: { eq: $type }) {
      name
      translation(language: $language) {
        value
      }
      slug
      page {
        translation(language: $language) {
          value
        }
      }
    }

    sideNavItems: allDirectusHelpCenterSection(
      filter: { type: { name: { eq: $type } }, status: { eq: "published" } }
      sort: { fields: sort, order: ASC }
    ) {
      nodes {
        name
        translation(language: $language) {
          value
        }
        slug
      }
    }

    activeSideNavItem: directusHelpCenterSection(name: { eq: $section }) {
      name
      translation(language: $language) {
        value
      }
      slug
    }

    qaForSection: allDirectusHelpCenterQa(
      filter: { type: { name: { eq: $type } }, section: { name: { eq: $section } }, status: { eq: "published" } }
      sort: { fields: sort, order: ASC }
    ) {
      nodes {
        translation(language: $language) {
          question
          answer
        }
      }
    }

    qaOnlyForType: allDirectusHelpCenterQa(
      filter: { section: { id: { eq: null } }, type: { name: { eq: $type } }, status: { eq: "published" } }
      sort: { fields: sort, order: ASC }
    ) {
      nodes {
        translation(language: $language) {
          question
          answer
        }
      }
    }

    allSectionItems: allDirectusHelpCenterSection(
      filter: { status: { eq: "published" } }
      sort: { fields: sort, order: ASC }
    ) {
      items: nodes {
        value
        name
        slug
        type {
          name
          value
        }
      }
    }
  }
`;

const { Panel } = Collapse;

const TextBase = `
  color: ${theme.colorsBlack};
  font-size: 18px;
`;

const StyledPanel = styled(Panel)`
  ${TextBase}
  background: white;
  padding-bottom: 2%;
`;

const SectionCol = styled(Col)`
  ${MobileDisplay}
`;

type TypeNavItem = {
  slug: string;
  name: string;
  translation: {
    value: string;
  };
  page: any;
};

type Props = {
  data: {
    topNavItems: {
      nodes: TypeNavItem[];
    };
    activeNavItem: TypeNavItem;
    sideNavItems: {
      nodes: TypeNavItem[];
    };
    activeSideNavItem: TypeNavItem;
    qaForSection: any;
    qaOnlyForType: any;
    allSectionItems: any;
  };
  pageContext?: any;
};

const HelpCenter = ({
  data: { topNavItems, activeNavItem, sideNavItems, activeSideNavItem, qaForSection, qaOnlyForType, allSectionItems },
  pageContext,
}: Props) => {
  const [openQuestion, setOpenQuestion] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { md } = useResponsive();
  const isMobile = !md;
  const { translateSlug } = useI18NContext();

  useEffect(() => {
    if (pageContext.section === undefined && sideNavItems.nodes.length) {
      dispatch(navigateTo(translateSlug(sideNavItems.nodes[0].slug)) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageContext.section]);

  const menuNavItems: any = useMemo(() => {
    const data = topNavItems.nodes.map((item: TypeNavItem) => {
      const submenu = allSectionItems.items.filter((sub: any) => {
        return item.name === sub.type.name;
      });
      return {
        value: item.translation.value,
        name: item.name,
        slug: item.slug,
        submenu,
      };
    });
    return data;
  }, [topNavItems, allSectionItems]);

  const handleMenuClick = useCallback(
    (e: any) => {
      const activeMenuItem = menuNavItems.find((item: any) => {
        return item.name === e.key;
      });
      const activeSectionItem = menuNavItems.find((item: any) => {
        return item.submenu.length
          ? !!item.submenu.find((submenu: any) => {
              return submenu.name === e.key;
            })
          : false;
      });
      if (activeMenuItem && activeMenuItem.slug) {
        dispatch(navigateTo(translateSlug(activeMenuItem?.submenu[0]?.slug || activeMenuItem.slug)) as any);
      }
      if (activeSectionItem) {
        const submenu = activeSectionItem.submenu.find((submenu: any) => {
          return submenu.name === e.key;
        });
        dispatch(navigateTo(translateSlug(submenu.slug)) as any);
      }
    },
    [dispatch, menuNavItems, translateSlug]
  );

  const typeItem: NavItem = useMemo(
    () => ({
      value: activeNavItem.translation.value,
      name: activeNavItem.name,
      slug: activeNavItem.slug,
    }),
    [activeNavItem]
  );

  const mappedSectionsMenu: any[] = useMemo(
    () =>
      sideNavItems.nodes.map((item: TypeNavItem) => {
        return {
          value: item.translation.value,
          name: item.name,
          slug: item.slug,
        };
      }),
    [sideNavItems]
  );

  const sectionItem: NavItem = useMemo(
    () =>
      pageContext.section
        ? {
            value: activeSideNavItem.translation.value,
            name: activeSideNavItem.name,
            slug: activeSideNavItem.slug,
          }
        : sideNavItems.nodes.length
        ? {
            value: sideNavItems.nodes[0].translation.value,
            name: sideNavItems.nodes[0].name,
            slug: sideNavItems.nodes[0].slug,
          }
        : {
            value: '',
            name: '',
            slug: '',
          },
    [
      activeSideNavItem.name,
      activeSideNavItem.slug,
      activeSideNavItem.translation.value,
      pageContext.section,
      sideNavItems.nodes,
    ]
  );

  const handleSectionMenuClick = useCallback(
    (name: string, slug: string) => {
      const activeItem = find(mappedSectionsMenu, (item) => {
        return item.name === name;
      });

      if (activeItem) {
        dispatch(navigateTo(translateSlug(slug)) as any);
      }
    },
    [dispatch, mappedSectionsMenu, translateSlug]
  );

  return (
    <>
      <PublicLayout seoTitle={'Help Center'}>
        <Container>
          <Row justify="center">
            <Col xs={24}>
              <StyledBreadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link key="1" to={translateSlug('/')}>
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{activeNavItem?.page?.translation?.value}</Breadcrumb.Item>
              </StyledBreadcrumb>
              <StyledHeader>{activeNavItem?.page?.translation?.value}</StyledHeader>
            </Col>
          </Row>

          <HorizontalSecondaryMenu
            activeItem={typeItem}
            activeSectionItem={sectionItem}
            handleClick={handleMenuClick}
            items={menuNavItems}
            isMobileParent={isMobile}
          />
          <Container>
            <Row justify="space-between">
              {mappedSectionsMenu.length > 0 ? (
                <SectionCol xs={24} lg={6}>
                  <SectionNavComponent
                    navItems={mappedSectionsMenu}
                    activeItem={sectionItem}
                    handleClick={handleSectionMenuClick}
                    isMobileParent={isMobile}
                  />
                </SectionCol>
              ) : null}

              {pageContext.section && activeSideNavItem ? (
                <Col xs={24} lg={18}>
                  <TypeHeader>{activeNavItem?.translation?.value}</TypeHeader>
                  <SectionHeader>{activeSideNavItem.translation.value}</SectionHeader>
                  <Collapse
                    defaultActiveKey={openQuestion}
                    expandIconPosition={'end'}
                    bordered={false}
                    accordion={true}
                    onChange={(key: string | string[]) => {
                      key && setOpenQuestion([key]);
                      !key && setOpenQuestion([]);
                    }}
                    expandIcon={({ isActive }) => (isActive ? <RemoveIcon /> : <PlusOutlined />)}
                  >
                    {qaForSection.nodes.map((item: any, key: number) => (
                      <StyledPanel
                        header={openQuestion[0] == key ? <b>{item.translation.question}</b> : item.translation.question}
                        key={key}
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.translation.answer }} />
                      </StyledPanel>
                    ))}
                  </Collapse>
                </Col>
              ) : null}

              {pageContext.type && !pageContext.section ? (
                <Col xs={24} lg={mappedSectionsMenu.length > 0 ? 18 : 24}>
                  <TypeHeader>{activeNavItem?.translation?.value}</TypeHeader>
                  <Collapse
                    defaultActiveKey={[]}
                    expandIconPosition={'end'}
                    bordered={false}
                    accordion={true}
                    expandIcon={({ isActive }) => (isActive ? <RemoveIcon /> : <PlusOutlined />)}
                  >
                    {qaOnlyForType.nodes.map((item: any, key: number) => (
                      <StyledPanel
                        header={openQuestion[0] == key ? <b>{item.translation.question}</b> : item.translation.question}
                        key={key}
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.translation.answer }} />
                      </StyledPanel>
                    ))}
                  </Collapse>
                </Col>
              ) : null}
            </Row>
          </Container>
        </Container>
      </PublicLayout>
    </>
  );
};

export default HelpCenter;
