import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import AdminLayout from '../layout/admin-layout';
import ReportsContainer from '../containers/reports/reports';
import ReportsList from '../containers/reports/reports-list';
import ReportDetail from '../containers/reports/report-detail';
import SEO from '../components/seo';
import { isAuthenticated } from '../state/selectors/auth';
import { navigateTo } from '../state/actions/routing';

export default function Reporting() {
  const loggedIn = useSelector(isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedIn) {
      dispatch(navigateTo('/login') as any);
    }
  }, [loggedIn, dispatch]);

  return (
    <AdminLayout>
      <SEO title="Reports" />
      <Router>
        <ReportsContainer path="/reporting">
          <ReportsList path="/" />
          <ReportDetail path="/:id" />
        </ReportsContainer>
      </Router>
    </AdminLayout>
  );
}
