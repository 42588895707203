import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { get, filter, isNil, find } from 'lodash';
import { Spin } from 'antd5';

import { NoMentors } from './no-mentors-list';

import { StyledMessage } from '../../scholarship/common/styles';
import SEO from '../../../../components/seo';
import { getUser, useRoles } from '../../../../state/selectors/auth';
import HorizontalSecondaryMenu from '../../../../components/common/horizontal-secondary-menu';
import PageHeader from '../../../../components/common/page-header';
import { InternalContainer } from '../../../../templates/basic-styles';
import UserDetailsContainer from '../common/user-details-container';
import MilestonesContainer from '../common/milestones-container';
import { IUser } from '../../../../types/models/auth';
import { getAssignedMentors, getMilestonesList, getUserFinishedCourses } from '../../../../apis/users/mentorship';
import SkillsContainer from '../common/skills-container';
import { tenantLabel } from '../../../../siteContent';
import MentorCompleteMilestoneSurveyModal from '../mentor-complete-milestone-survey/mentor-complete-milestone-survey';
import { openChat, setIsLoadingChat } from '../../../../state/actions/message';
import Chat from '../../../../components/chat/index';
import {
  isMentorMatchEnabled,
  isConferenceMentorMatchEnabled,
  isRealTimeChatEnabled as $isRealTimeChatEnabled,
} from '../../../../state/selectors/tenant';
import { MentorshipActor } from '../../../../types/models/mentorship';

const StyledHorizontalMenu = styled.div`
  background-color: white;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  background-color: white;
  position: relative;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin: 25px;
`;

const PartnerBorder = styled.hr`
  background-color: #f0f0f0;
  width: 95%;
  height: 1px;
  border-style: none;
  margin: auto;
`;

const ChatContent = styled(Chat)`
  padding: 0 0 24px 0;
`;

const MentorsList = (_: RouteComponentProps) => {
  const menuItems = [
    {
      name: 'milestonesActive',
      value: 'Milestones Active',
      slug: '/mentor-match',
    },
    {
      name: 'milestonesComplete',
      value: 'Milestones Complete',
      slug: '/mentor-match',
    },
  ];

  const dispatch = useDispatch();
  const loggedUser: IUser | null = useSelector(getUser);
  const [activeNavItem, setActiveNavItem] = useState(menuItems[0]);
  const [completeSurveyModalVisible, setCompleteSurveyModalVisible] = useState(false);
  const [selectedMentorId, setSelectedMentorId] = useState('');
  const [selectedMilestoneId, setSelectedMilestoneId] = useState('');
  const [annualMentors, setAnnualMentors] = useState([] as MentorshipActor[]);
  const [programMentors, setProgramMentors] = useState([] as MentorshipActor[]);

  const isMentorMatchEnable = useSelector(isMentorMatchEnabled());
  const isConferenceMentorMatchEnable = useSelector(isConferenceMentorMatchEnabled());
  const { isMentee, isProgramMentee } = useRoles();
  const isRealTimeChatEnabled = useSelector($isRealTimeChatEnabled());

  const {
    data: userData,
    isLoading: isUserDataLoading,
    refetch: refetchUserData,
    error,
  } = useQuery([getAssignedMentors.QUERY_KEY, { userId: loggedUser?.id }], () => getAssignedMentors(), {
    enabled: !!loggedUser,
  });
  const { data: milestonesData, isLoading: isMilestonesDataLoading } = useQuery(getMilestonesList.QUERY_KEY, () =>
    getMilestonesList()
  );
  const { data: finishedCourses, isLoading: isFinishedCoursesLoading } = useQuery(
    getUserFinishedCourses.QUERY_KEY,
    () => getUserFinishedCourses(true, get(loggedUser, 'id', ''))
  );

  useEffect(() => {
    const mentors = filter(userData, function (mentor) {
      if (isActiveMilestonesTab()) {
        return isNil(mentor.completedDate);
      } else {
        return !isNil(mentor.completedDate);
      }
    });

    if (isMentorMatchEnable && isMentee) {
      setAnnualMentors(filter(mentors, (mentor) => isNil(mentor.programId)));
    }
    if (isConferenceMentorMatchEnable && isProgramMentee) {
      setProgramMentors(filter(mentors, (mentor) => !isNil(mentor.programId)));
    }
  }, [userData, isMentorMatchEnable, isConferenceMentorMatchEnable, activeNavItem]);

  const handleOpenMentorBio = (userId: string) => {
    navigate(`/myhsf/my-mentors/${userId}`);
  };

  const handleSendMessage = (userId: string) => {
    dispatch(setIsLoadingChat(true));
    dispatch(openChat(userId));
  };

  const buttons = [
    {
      title: 'Mentor Bio',
      type: 'default',
      handler: handleOpenMentorBio,
    },
    {
      title: 'Send Message',
      type: 'primary-blue',
      handler: handleSendMessage,
      opensChat: true,
    },
  ];

  const handleSecondaryMenuClick = (sectionName: string) => {
    const navItem = find(menuItems, (item: { name: string }) => {
      return item.name === get(sectionName, 'key');
    });
    if (navItem) {
      setActiveNavItem(navItem);
    }
  };

  const getMilestones = (milestoneType: string): any => {
    return filter(get(milestonesData, 'items', []), function (milestone) {
      return milestoneType === milestone.milestoneType;
    });
  };

  const getCompletedTracks = () => {
    const completedTracks = filter(getMilestones('annual'), function (milestone) {
      return milestone.tracks.map((track: any) => {
        return track.status === 'completed';
      });
    });

    return completedTracks.reduce((names: string[], trackName: { name: string }) => {
      names.push(trackName.name);
      return names;
    }, []);
  };

  const isActiveMilestonesTab = (): boolean => {
    return activeNavItem.name === 'milestonesActive';
  };

  const markDiscussionCompleteHandler = (userId: string, milestoneId: string) => {
    setSelectedMentorId(userId);
    setSelectedMilestoneId(milestoneId);
    setCompleteSurveyModalVisible(true);
  };

  const renderPageContent = (mentorsList: any) => {
    if (isUserDataLoading && isMilestonesDataLoading && isFinishedCoursesLoading) {
      return undefined;
    }

    return (
      <>
        {userData &&
          mentorsList.map((mentor: any) => {
            return (
              <SectionWrapper key={mentor.id}>
                <UserDetailsContainer userData={mentor} buttonsList={buttons} />
                {milestonesData && isActiveMilestonesTab() && (
                  <>
                    <PartnerBorder />
                    <MilestonesContainer
                      markDiscussionCompleteHandler={markDiscussionCompleteHandler}
                      finishedCourses={finishedCourses}
                      milestones={getMilestones(mentor.milestoneType)}
                      completedMilestones={mentor.completedMilestones}
                      userId={mentor.id}
                      milestoneSurvey={mentor.milestoneSurvey}
                    />
                  </>
                )}
              </SectionWrapper>
            );
          })}
      </>
    );
  };

  return (
    <>
      <SEO title="My Mentors" />
      <PageHeader
        title="My Mentors"
        breadcrumb={[
          <Link key="1" to="/myhsf">
            {tenantLabel} Dashboard
          </Link>,
          'My Mentors',
        ]}
        fullWidth
        isResponsive
        noShadow
      />
      <StyledHorizontalMenu>
        <HorizontalSecondaryMenu activeItem={activeNavItem} handleClick={handleSecondaryMenuClick} items={menuItems} />
      </StyledHorizontalMenu>
      <InternalContainer>
        <Spin size="large" spinning={isUserDataLoading && isMilestonesDataLoading && isFinishedCoursesLoading}>
          {annualMentors.length > 0 && (
            <>
              <SectionTitle>Mentors</SectionTitle>
              <SkillsContainer completedTracksList={getCompletedTracks()} />
              {!error && renderPageContent(annualMentors)}
              {error && <StyledMessage>{(error as any).message}</StyledMessage>}
            </>
          )}
          {programMentors.length > 0 && (
            <>
              <SectionTitle>Program Mentors</SectionTitle>
              {!error && renderPageContent(programMentors)}
              {error && <StyledMessage>{(error as any).message}</StyledMessage>}
            </>
          )}
          {!annualMentors.length && !programMentors.length ? <NoMentors active={isActiveMilestonesTab()} /> : null}
        </Spin>
        {isRealTimeChatEnabled && <ChatContent />}
      </InternalContainer>
      <MentorCompleteMilestoneSurveyModal
        closeModal={() => {
          setCompleteSurveyModalVisible(false);
          refetchUserData();
          setSelectedMentorId('');
          setSelectedMilestoneId('');
        }}
        isVisible={completeSurveyModalVisible}
        mentorId={selectedMentorId}
        milestoneId={selectedMilestoneId}
      />
    </>
  );
};

export default MentorsList;
