import { ITableQueryParams, RequestData } from '../components/table';
import { FormStatus } from '../types/form';
import { ISearchStageUsersRequest } from '../types/models/program-management';

/**
 * Returns the offset given the pageSize and current page
 *
 * @export
 * @param {number} pageSize
 * @param {number} current
 * @returns
 */
export function getPageOffset(pageSize: number, current: number) {
  return pageSize * (current - 1);
}

export function predicateBy({ field = '', order = 'ascend' }: ITableQueryParams['sort']) {
  return function sortBy(a: any, b: any) {
    // basic comparison logic to apply most cases (not locale string)
    const valueA = typeof a[field] === 'string' ? a[field].toUpperCase() : a[field];
    const valueB = typeof b[field] === 'string' ? b[field].toUpperCase() : b[field];
    let result = 0;
    if (valueA > valueB) {
      result = 1;
    } else if (valueA < valueB) {
      result = -1;
    }
    if (result !== 0) {
      return order === 'ascend' ? result : result * -1;
    }
    return result;
  };
}

/**
 * This is used for static table rendering as well as mocking dynamic table
 *
 * @export
 * @template T
 * @param {{ data: T[]; params: ITableQueryParams }} { data, params }
 * @returns {RequestData<T>}
 */
export function performQuery<T = any>({ data, params }: { data: T[]; params: ITableQueryParams }): RequestData<T> {
  const { limit, offset, searchText, filters, sort } = params;
  let paginatedData: T[] = data;

  if (searchText) {
    paginatedData = paginatedData.filter((data: T) =>
      Object.values(data as any).some((value) => {
        return String(value).toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      })
    );
  }

  if (filters && Object.keys(filters).length) {
    paginatedData = paginatedData.filter((data: T) =>
      Object.keys(filters).every((key) => {
        const value = data[key as keyof T];
        return filters[key].map((item) => String(item).toLowerCase()).includes(String(value).toLowerCase());
      })
    );
  }

  if (sort && Object.keys(sort).length) {
    paginatedData = paginatedData.concat().sort(predicateBy(sort));
  }

  // capture the total number before slicing
  const total = paginatedData.length;
  paginatedData = paginatedData.slice(offset, offset + limit);

  return {
    data: paginatedData,
    total,
    success: true,
  };
}

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 * This is the default transformQuery all admin tables use to hook different filter parameters
 *
 * @export
 * @template T
 * @param {params: ITableQueryParams }
 * @returns {ISearchStageUsersRequest}
 */
export function transformQuery({ limit, offset, searchText, filters, sort }: ITableQueryParams) {
  const hasSort = !!(sort && sort.field);
  const filterParams = Object.keys(filters).reduce((acc: { [key: string]: string }, key) => {
    const filterItems = filters[key].concat();
    const flaggedIndex = filterItems.indexOf(FormStatus.Flagged);

    if (flaggedIndex > -1) {
      filterItems.splice(flaggedIndex, 1);
      acc[FormStatus.Flagged] = 'true';
    }
    if (filterItems.length) {
      acc[key] = filterItems.join(',');
    }
    return acc;
  }, {});

  const queryParam: ISearchStageUsersRequest = {
    limit,
    offset,
    ...filterParams,
    search: searchText,
    sortBy: hasSort ? sort.field : undefined,
    sortDirection: hasSort ? (sort.order === 'ascend' ? SortDirection.ASC : SortDirection.DESC) : undefined,
  };

  return queryParam;
}
