import {
  useQuery as $useQuery,
  QueryKey,
  QueryFunction,
  QueryObserverOptions as $QueryOptions,
  UseQueryResult,
} from 'react-query';
import { useRef } from 'react';

export type QueryOptions<TResult> = $QueryOptions<TResult> & {
  useLastResultAsInitialData?: boolean;
};

export function useQuery<TResult extends object>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TResult, QueryKey>,
  { initialData, useLastResultAsInitialData = true, onSuccess, ...queryConfig }: QueryOptions<TResult> = {}
): UseQueryResult<TResult> {
  // should the data be saved based on the `queryKey`?
  // https://github.com/tannerlinsley/react-query/issues/78#issuecomment-573079213
  const initialDataRef = useRef(initialData);

  const query = $useQuery<TResult>(queryKey, queryFn, {
    ...queryConfig,
    initialData: useLastResultAsInitialData ? initialDataRef.current : initialData,
    onSuccess: useLastResultAsInitialData
      ? (data) => {
          initialDataRef.current = data;
          if (onSuccess) {
            return onSuccess(data);
          }
        }
      : onSuccess,
  });

  return query;
}
