import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Ticker from 'react-ticker';

import { InternalContainer, StyledButton, StyledH1 } from './section-styles';

import { getRealNodes } from '../../utils/contentFilter';

type SectionProps = {
  title: string;
  ctaLabel: string;
  partnerImages: {
    nodes: {
      PartnerImage: string;
    };
  };
  handleClick: (link: string) => void;
};

type PartnerImage = {
  partner_logo: {
    data: {
      full_url: string;
    };
  };
  partner_slug: string;
  partner_title: string;
  ticker_row: string;
  display_partner_page: boolean;
};

type MappedPartnerImage = {
  slug: string;
  image: string;
  altText: string;
  row: string;
  displayPartnerPage: boolean;
};

const PartnerSection = styled.section`
  padding-bottom: 96px;
  padding-top: 72px;
  position: relative;
  max-width: 1120px;
`;

const PartnerBorder = styled.hr`
  background-color: #f0f0f0;
  width: 58%;
  height: 1px;
  border-style: none;
  margin: 0 auto;
  margin-bottom: 72px;
`;
const StyledCol = styled(Col)`
  text-align: center;
`;

const Tagline = styled(StyledH1)`
  letter-spacing: -0.5px;
  line-height: 48px;
  padding-bottom: 64px;
`;

const LogosContainer = styled(Row)`
  margin-bottom: 64px;

  & .ticker:not(:last-child) {
    margin-bottom: 50px;
  }

  &:hover a {
    opacity: 0.4;
  }
`;

const LogoRow = styled.div`
  display: flex;
`;

const Logo = styled(Link)`
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 50px;

  &&:hover {
    opacity: 1;
  }
`;

const LogoImg = styled.img`
  height: auto;
  width: auto;
  max-height: 80px;
  max-width: 80px;
`;

export default function PartnerComponent({ title, ctaLabel, partnerImages, handleClick }: SectionProps) {
  const [move, setMove] = useState<boolean>(true);

  useEffect(() => {
    if (typeof document.addEventListener !== 'undefined') {
      const pauseTicker = () => {
        if (document.visibilityState === 'hidden') {
          setMove(false);
        } else {
          setMove(true);
        }
      };

      document.addEventListener('visibilitychange', pauseTicker);

      return () => {
        document.removeEventListener('visibilitychange', pauseTicker);
      };
    }
  }, []);

  const mappedPartnerImages = getRealNodes(partnerImages).map((item: PartnerImage) => {
    return {
      image: item.partner_logo.data.full_url,
      slug: item.partner_slug,
      altText: item.partner_title,
      row: item.ticker_row,
      displayPartnerPage: item.display_partner_page,
    };
  });

  const handleOnClick = useCallback(() => {
    handleClick('/corporate-partners');
  }, [handleClick]);

  const handleMouseOver = useCallback(() => {
    setMove(false);
  }, []);

  const handleMouseOut = useCallback(() => {
    setMove(true);
  }, []);

  const tickers = () => {
    const rows: MappedPartnerImage[][] = [];

    mappedPartnerImages.forEach((item: MappedPartnerImage) => {
      const row = parseInt(item.row);

      if (row) {
        if (rows[row]) {
          rows[row].push(item);
        } else {
          rows[row] = [item];
        }
      }
    });

    return rows.map((row: MappedPartnerImage[], rowIndex: number) => {
      if (row) {
        const logos = row.map((item: MappedPartnerImage | any, i: number) => {
          return (
            <div key={i} title={item.altText || ''}>
              <Logo to={item.slug || '/'} disabled={!item.displayPartnerPage}>
                <LogoImg src={item.image || ''} alt={item.altText || ''} />
              </Logo>
            </div>
          );
        });

        const tickerContents = [...Array(Math.ceil(10 / row.length))].map(() => logos);

        return (
          <Ticker move={move} key={rowIndex}>
            {() => <LogoRow>{tickerContents}</LogoRow>}
          </Ticker>
        );
      }
    });
  };

  return (
    <InternalContainer>
      <PartnerSection>
        <PartnerBorder />
        <Row justify="center">
          <StyledCol xs={24}>
            <Tagline>{title}</Tagline>
          </StyledCol>
          <Col xs={24}>
            <div
              onMouseOver={handleMouseOver}
              onFocus={handleMouseOver}
              onMouseOut={handleMouseOut}
              onBlur={handleMouseOut}
            >
              <LogosContainer>{tickers()}</LogosContainer>
            </div>
          </Col>
          <StyledCol xs={24}>
            <StyledButton type="primary-blue" onClick={handleOnClick}>
              {ctaLabel}
            </StyledButton>
          </StyledCol>
        </Row>
      </PartnerSection>
    </InternalContainer>
  );
}
