import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchClassLevelsAction, fetchEnrollmentStatusesAction, fetchLookupAction } from '../actions/lookup';
import { LookupOption } from '../../types/models/user-management';
import { LookupType } from '../../types/models/lookup';
import { RootState } from '../reducers/index';

export const getGenderList = (state: RootState) => state.lookup?.genders;

export const getRaceList = (state: RootState) => state.lookup?.ethnicities;

export const getRoleList = (state: RootState) => state.lookup?.roles;

export const getClassLevelsList = (state: RootState) => state.lookup?.classLevels;

export const getResidencyList = (state: RootState) => state.lookup?.residencies;

export const getAllLookupsFromState = (state: RootState) => state.lookup;

export const getEnrollmentStatusesList = (state: RootState) => state.lookup?.enrollmentStatuses;

export const getLookupFromStateByName = (lookupName?: LookupType) => {
  return (state: RootState) => {
    return (lookupName && state.lookup && state.lookup[lookupName]) || [];
  };
};

export const useRolesMap = (): { [key: string]: string } => {
  const dispatch = useDispatch();
  const fetchLookupList = useCallback(() => dispatch(fetchLookupAction() as any), [dispatch]);
  const roles: LookupOption[] = useSelector(getRoleList);

  useEffect(() => {
    if (!roles.length) {
      fetchLookupList();
    }
  }, [roles, fetchLookupList]);

  return roles.reduce((result, item) => {
    return {
      ...result,
      [item.value]: item.label,
    };
  }, {});
};

export const useClassLevels = () => {
  const dispatch = useDispatch();
  const fetchClassLevelsList = useCallback(() => dispatch(fetchClassLevelsAction() as any), [dispatch]);
  const classLevels: LookupOption[] = useSelector(getClassLevelsList);

  useEffect(() => {
    if (!classLevels.length) {
      fetchClassLevelsList();
    }
  }, [classLevels, fetchClassLevelsList]);
  return classLevels;
};

export const useRolesList = () => {
  const dispatch = useDispatch();
  const fetchLookupList = useCallback(() => dispatch(fetchLookupAction() as any), [dispatch]);
  const roles: LookupOption[] = useSelector(getRoleList);

  useEffect(() => {
    if (!roles.length) {
      fetchLookupList();
    }
  }, [roles, fetchLookupList]);
  return roles;
};

export const useEnrollmentStatuses = () => {
  const dispatch = useDispatch();
  const fetchEnrollmentStatusList = useCallback(() => dispatch(fetchEnrollmentStatusesAction() as any), [dispatch]);
  const enrollmentStatuses: LookupOption[] = useSelector(getEnrollmentStatusesList);

  useEffect(() => {
    if (!enrollmentStatuses.length) {
      fetchEnrollmentStatusList();
    }
  }, [enrollmentStatuses, fetchEnrollmentStatusList]);
  return enrollmentStatuses;
};
