import { CustomAxiosError } from '../types/models/error';

/**
 * Populate `message` from the response to the `error.message`
 *
 * @export
 * @param {CustomAxiosError} error
 * @returns  {AxiosError}
 */

export function createError(error: CustomAxiosError) {
  if (error.isAxiosError) {
    const { response: { data, status } = { data: {} as any, status: 0 } } = error;

    if (data.message) {
      error.message = data.message;
    }
    if (data.code) {
      error.code = data.code;
    }

    if (data.validationErrors) {
      error.validationErrors = data.validationErrors;
    }

    if (data.data) {
      error.data = data.data;
    }

    if (status) {
      error.status = status;
    }
  }
  return error;
}
