import axios from 'axios';

import { ISettings, ResetPassword, UpdateEmail } from '../../types/models/user-settings';
import { createError } from '../error';
import responseParser from '../response-parser';
import { API_URL } from '../../constants';
import { CustomAxiosError } from '../../types/models/error';

export async function getSettings(userId: string): Promise<ISettings> {
  const url = `${API_URL}/users/${userId}/settings`;

  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}
getSettings.QUERY_KEY = 'user:getSettings';

/**
 * Edit settings with all the payload data
 * @param id
 * @param settings
 */
export async function editSettings(settings: Partial<ISettings>, userId: string): Promise<ISettings> {
  const url = `${API_URL}/users/${userId}/settings`;
  try {
    const response = await axios.put(url, settings);
    const data: ISettings = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

/**
 * Update user email address
 * @param emailForm
 * @param userId
 */
export async function updateEmail(emailForm: UpdateEmail, userId: string) {
  const url = `${API_URL}/users/${userId}/update-email`;
  try {
    await axios.post(url, {
      newEmail: emailForm.newEmail,
      password: emailForm.currentPassword,
    });
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}

/**
 * Update user password
 * @param passwords
 */
export async function resetPassword(passwords: ResetPassword, userId: string): Promise<void> {
  const url = `${API_URL}/users/${userId}/change-password`;
  try {
    await axios.post(url, {
      password: passwords.currentPassword,
      newPassword: passwords.newPassword,
      confirmNewPassword: passwords.confirmPassword,
    });
  } catch (err) {
    throw createError(err as CustomAxiosError);
  }
}
