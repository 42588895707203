import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby-link';
import { useMutation } from 'react-query';
import { parse } from 'query-string';

import PublicLayout from '../layout/public-layout';
import { IEmailConfirmation, IResetPasswordFormData, IVerificationDetails } from '../types/models/auth';
import {
  MESSAGE_STATUS,
  PASSWORD_RESET_CONFIRMATION_URL,
  PASSWORD_RESET_EXPIRATION_URL,
  PASSWORD_RESET_LINK_EXPIRED,
} from '../constants';
import { resetPassword } from '../apis/auth';
import ResetPasswordForm from '../components/user/auth/reset-password-form';
import SEO from '../components/seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function ResetPassword({ location }: RouteComponentProps<Record<string, any>>) {
  const qs = location ? parse(location.search) : { linkId: '', userId: '' };

  const linkId: string = qs['linkId'] as string;
  const userId: string = qs['userId'] as string;

  const [status, setStatus] = useState<string>('');

  const { mutateAsync: changePassword } = useMutation<
    IEmailConfirmation,
    unknown,
    { formData: IResetPasswordFormData; vDetails: IVerificationDetails }
  >(({ formData, vDetails }) => resetPassword(formData, vDetails));

  const handleResetPassword = useCallback(
    async (formData: IResetPasswordFormData) => {
      try {
        await changePassword({ formData, vDetails: { linkId, userId } });
        navigate(PASSWORD_RESET_CONFIRMATION_URL, { state: { userId } });
      } catch (e: any) {
        if (e && e.message == PASSWORD_RESET_LINK_EXPIRED.ERROR_MESSAGE) {
          navigate(PASSWORD_RESET_EXPIRATION_URL);
        } else {
          setStatus(MESSAGE_STATUS.ERROR);
        }
      }
    },
    [changePassword, linkId, userId]
  );

  return (
    <div>
      <SEO title="Sign In" />
      <PublicLayout>
        <Container>
          <ResetPasswordForm onResetPassword={handleResetPassword} status={status} />
        </Container>
      </PublicLayout>
    </div>
  );
}
