import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby-link';

import PublicLayout from '../layout/public-layout';
import {
  EMAIL_CONFIRMATION,
  EMAIL_CONFIRMATION_URL,
  LOG_IN_URL,
  SIGN_UP_CONFIRMATION,
  SIGN_UP_URL,
} from '../constants';
import { navigateTo } from '../state/actions/routing';
import { resetErrorAction } from '../state/actions/auth';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import SEO from '../components/seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function EmailConfirmation({ location }: RouteComponentProps<Record<string, any>>) {
  const userId = get(location, 'state.userId');
  const { pathname } = location || {};
  const dispatch = useDispatch();

  useEffect(() => {
    let redirectPath;
    if (!userId && pathname == EMAIL_CONFIRMATION_URL) {
      redirectPath = SIGN_UP_URL;
    }

    if (redirectPath) {
      dispatch(navigateTo(redirectPath) as any);
    }
  }, [pathname, dispatch, userId]);

  const handleSignIn = useCallback(() => {
    dispatch(resetErrorAction(LOG_IN_URL));
    navigate(LOG_IN_URL);
  }, [dispatch]);

  return (
    <div>
      <SEO title="Sign In" />
      <PublicLayout>
        <Container>
          <EmailVerificationConfirmation
            onButtonClick={handleSignIn}
            headingText={EMAIL_CONFIRMATION.DISPLAY_MESSAGE}
            paragraphText={EMAIL_CONFIRMATION.INSTRUCTION_TEXT}
            buttonText={EMAIL_CONFIRMATION.BUTTON_TEXT}
            linkText={SIGN_UP_CONFIRMATION.LINK_TEXT}
          />
        </Container>
      </PublicLayout>
    </div>
  );
}
