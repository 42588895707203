import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import theme from '../../../../theme';
import { getAssignedCoachees, getCoachee } from '../../../../apis/users/coaches-match';
import Loader from '../../../../components/common/loader';
import SEO from '../../../../components/seo';
import PageHeader from '../../../../components/common/page-header';
import { StyledMessage } from '../../scholarship/common/styles';
import UserContent from '../../../../components/common/user-content';
import { formatUniversity } from '../../mentorship/common';
import { UserContentLayoutType } from '../../../../types/layout';
import UserBioHeader from '../../../../components/user/user-bio/user-bio-header';
import { tenantLabel } from '../../../../siteContent';
import { Button } from '../../../../components';
import UserBioContentCard from '../../../../components/user/user-bio/user-bio-content-card';
import { openChat, setIsLoadingChat } from '../../../../state/actions/message';
import { getIsLoadingChat, getUserIdForChat } from '../../../../state/selectors/message';

type CoacheeBioProps = {
  userId: string;
};

const CoacheeSkillRow = styled.div`
  display: list-item;
  list-style: disc inside none;
`;

const UserContentCss = css`
  padding-top: 30px;
  background-color: ${theme.colorsLightestGrey};
`;

const CoacheeBio: React.FC<RouteComponentProps<CoacheeBioProps>> = ({ userId }) => {
  const dispatch = useDispatch();
  const isLoadingChat = useSelector(getIsLoadingChat);
  const chatUserId = useSelector(getUserIdForChat);

  const {
    data: coachee,
    isLoading,
    error,
  } = useQuery([getCoachee.QUERY_KEY, { userId: userId as string }], ({ queryKey }: any) =>
    getCoachee(queryKey[1].userId)
  );

  const { data: assignedCoachees } = useQuery([getAssignedCoachees.QUERY_KEY, { userId: userId }], () =>
    getAssignedCoachees()
  );

  if (isLoading) {
    return <Loader />;
  }

  const breadcrumbs: Array<React.ReactNode | string> = [
    <Link key="1" to="/myhsf">
      {tenantLabel} Dashboard
    </Link>,
    <Link key="2" to="/myhsf/my-coachees">
      {tenantLabel} Coachees
    </Link>,
    `${coachee?.firstName} ${coachee?.lastName}`,
  ];

  const coacheeAssignment = assignedCoachees?.map((assignedCoach) => {
    if (userId === assignedCoach.id) {
      return <CoacheeSkillRow>{assignedCoach.assignment}</CoacheeSkillRow>;
    }
  });

  const sendMessage = (userId: string | undefined) => {
    if (userId) {
      dispatch(setIsLoadingChat(true));
      dispatch(openChat(userId));
    }
  };

  const location = [coachee?.city, coachee?.state].filter((v) => v !== null && v !== undefined && v !== '').join(', ');

  const coacheeInfo = [
    {
      label: 'Location',
      value: location,
    },
    {
      label: 'Current University',
      value: formatUniversity(coachee?.university),
    },
    {
      label: 'Current Class Level',
      value: coachee?.classLevel,
    },
    {
      label: 'High School',
      value: formatUniversity(coachee?.highSchool),
    },
    {
      label: 'Undergraduate Major',
      value: coachee?.bachelorMajors?.join(', '),
    },
  ];

  return (
    <>
      <SEO title="Coaches" />
      <PageHeader title={''} breadcrumb={breadcrumbs} noShadow />
      {!error && (
        <>
          <UserBioHeader
            title={`${coachee?.firstName} ${coachee?.lastName}`}
            image={coachee?.image}
            subtitle={coachee?.primaryMajor}
            userInfoRows={coacheeInfo}
            button={
              <Button
                key={'send-message'}
                type={'primary-blue'}
                onClick={() => sendMessage(coachee?.id)}
                block
                loading={isLoadingChat && chatUserId === coachee?.id}
              >
                Send Message
              </Button>
            }
          />
          <UserContent type={UserContentLayoutType.DASHBOARD} css={UserContentCss}>
            {coacheeAssignment && <UserBioContentCard title={'What I Need Help With'} content={coacheeAssignment} />}
          </UserContent>
        </>
      )}
      {error && <StyledMessage>{(error as any).message}</StyledMessage>}
    </>
  );
};

export default CoacheeBio;
