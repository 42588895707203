import React, { useState } from 'react';

import { ScholarshipFinderType } from '../../../../types/models/scholarship-finder';
import { getScholarshipFinderToPages, IScholarshipFinderQuery } from '../../../../apis/public/scholarship-finder';
import { useQuery } from '../../../../hooks/use-query';

// TODO add colleges data typ

interface IScholarshipFinderContext {
  setFilterQuery?: (queryOptions: IScholarshipFinderQuery) => void;
  scholarshipLoading: boolean;
  scholarshipData?: ScholarshipFinderType | null;
  queryOptions: IScholarshipFinderQuery;
  scholarshipsError?: Error | null;
}

const initialContextValues = {
  scholarshipLoading: false,
  queryOptions: {},
};

const Context = React.createContext<IScholarshipFinderContext>(initialContextValues);

const ScholarshipFinderContextProvider: React.FC<any> = ({ children }) => {
  const [filterQueryOptions, setFilterQueryOptions] = useState<IScholarshipFinderQuery>({ offset: 0 });

  const {
    data: scholarshipData,
    isLoading: scholarshipLoading,
    error: scholarshipsError,
  } = useQuery(
    [getScholarshipFinderToPages.QUERY_KEY, filterQueryOptions],
    ({ queryKey }: any) => getScholarshipFinderToPages(queryKey[1]),
    {
      initialData: { scholarships: [], totalCount: 0 },
    }
  );

  return (
    <Context.Provider
      value={{
        scholarshipsError: scholarshipsError as any,
        queryOptions: filterQueryOptions,
        scholarshipData,
        scholarshipLoading,
        setFilterQuery: (options: IScholarshipFinderQuery) => {
          setFilterQueryOptions((current) => {
            const offset = current.offset != options?.offset ? options?.offset : 0;
            return { ...current, ...options, offset };
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

interface IUseScholarshipFinderQuery {
  data?: ScholarshipFinderType | null;
  loading: boolean;
  setFilterQuery: (queryOptions: IScholarshipFinderQuery) => void;
  queryOptions: IScholarshipFinderQuery;
  scholarshipsError?: Error | null;
}

export const useScholarshipFinderQuery = (): IUseScholarshipFinderQuery => {
  const {
    setFilterQuery: _setFilterQuery,
    scholarshipData: data,
    scholarshipLoading: loading,
    queryOptions,
    scholarshipsError,
  } = React.useContext(Context);

  const setFilterQuery = (options: IScholarshipFinderQuery) => {
    if (_setFilterQuery) {
      _setFilterQuery(options);
    }
  };

  return { data, loading, setFilterQuery, queryOptions, scholarshipsError };
};

export default ScholarshipFinderContextProvider;
