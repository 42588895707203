import styled from '@emotion/styled';
import { Breadcrumb, Col, Dropdown, List, Menu, Row } from 'antd';
import { Link, graphql } from 'gatsby';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header, InternalContainer, PageContent, StyledBreadcrumb } from './basic-styles';

import { Button } from '../components/';
import HorizontalSecondaryMenu from '../components/common/horizontal-secondary-menu';
import { useI18NContext } from '../i18n';
import PublicLayout from '../layout/public-layout';
import { programShortName } from '../siteContent';
import { navigateTo } from '../state/actions/routing';
import theme from '../theme';
import { NavItem } from '../types/cms/models/nav-item';

const StyledHeader = styled(Header)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Container = styled(InternalContainer)`
  padding-top: 40px;
`;

const YearRow = styled(Row)`
  flex-direction: column;
  color: ${theme.colorsBlack};
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 15px;
  padding: 5px;
`;

const LinkRow = styled(Row)`
  flex-direction: column;
  margin-bottom: 5px;
  font-size: 18px;
  padding: 5px;
`;

type TypeNavItem = {
  slug: string;
  name: string;
  translation: {
    value: string;
  };
};

type Props = {
  data: {
    topNavItems: {
      nodes: TypeNavItem[];
    };
    activeNavItem: any;
    report: {
      reports: any;
      translation: {
        text: string;
      };
    };
  };
  pageContext?: any;
};

// The query used to provide the page data
export const query = graphql`
  query ($type: String!, $language: String) {
    topNavItems: allDirectusFinancialsType(
      filter: { page: { id: { ne: null } }, status: { eq: "published" } }
      sort: { fields: sort, order: ASC }
    ) {
      nodes {
        name
        translation(language: $language) {
          value
        }
        slug
      }
    }

    activeNavItem: directusFinancialsType(name: { eq: $type }) {
      name
      slug
      page {
        translation(language: $language) {
          value
        }
      }
    }

    report: directusFinancialsReport(type: { name: { eq: $type } }) {
      translation(language: $language) {
        text
      }
      reports {
        year
        name
        url
      }
    }
  }
`;

declare const window: any;

const Financial = function HelpCenter({ data: { topNavItems, activeNavItem, report } }: Props) {
  const dispatch = useDispatch();
  const [actionStyle] = useState<any>(false);
  const { translateSlug } = useI18NContext();

  const menuNavItems: any = useMemo(() => {
    const data = topNavItems.nodes.map((item: TypeNavItem) => {
      return {
        value: item.translation.value,
        name: item.name,
        slug: item.slug,
        submenu: [],
      };
    });
    return data.reverse();
  }, [topNavItems]);

  const typeItem: NavItem = useMemo(
    () => ({
      value: activeNavItem?.translation?.value,
      name: activeNavItem.name,
      slug: activeNavItem.slug,
    }),
    [activeNavItem]
  );

  const files: any = useMemo(() => {
    return ((report && report.reports) || []).sort((a: any, b: any) => (b.year > a.year ? 1 : -1));
  }, [report]);

  const handleMenuClick = useCallback(
    (e: any) => {
      const activeMenuItem = menuNavItems.find((item: any) => {
        return item.name === e.key;
      });
      if (activeMenuItem && activeMenuItem.slug) {
        dispatch(navigateTo(translateSlug(activeMenuItem.slug)) as any);
      }
    },
    [dispatch, menuNavItems, translateSlug]
  );

  const getMenu = useCallback((file: any) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            window.open(file.url, '_blank');
          }}
        >
          View PDF
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            console.log('Share PDF');
          }}
        >
          Share PDF
        </Menu.Item>
      </Menu>
    );
  }, []);

  const options = useCallback(
    (file: any) => {
      return (
        <>
          <Row align="middle" justify="start">
            <Dropdown overlay={getMenu(file)}>
              <Button type="link">{file.name}</Button>
            </Dropdown>
          </Row>
        </>
      );
    },
    [getMenu]
  );

  return (
    <>
      <PublicLayout seoTitle={`${programShortName} Financial Reports`}>
        <Container>
          <Row justify="center">
            <Col xs={24}>
              <StyledBreadcrumb separator=">">
                <Breadcrumb.Item>
                  <Link key="1" to={translateSlug('/')}>
                    Home
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{activeNavItem?.page?.translation.value}</Breadcrumb.Item>
              </StyledBreadcrumb>
              <StyledHeader>{activeNavItem?.page?.translation.value}</StyledHeader>
            </Col>
          </Row>

          <HorizontalSecondaryMenu activeItem={typeItem} handleClick={handleMenuClick} items={menuNavItems} />

          <Container>
            <Row justify="space-between">
              <Col xs={24}>
                {report && <PageContent dangerouslySetInnerHTML={{ __html: report.translation.text }} />}
              </Col>
            </Row>

            {files.length > 0 && (
              <Row justify="space-between">
                <Col xs={24}>
                  <List
                    itemLayout="horizontal"
                    size="large"
                    dataSource={files}
                    renderItem={(item: any) => (
                      <List.Item>
                        <div>
                          <YearRow>{item.year}</YearRow>
                          {actionStyle ? (
                            <LinkRow>{options(item)}</LinkRow>
                          ) : (
                            <LinkRow>
                              <a href={item.url} target="_blank" rel="noopener noreferrer">
                                {item.name}
                              </a>
                            </LinkRow>
                          )}
                        </div>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </Container>
      </PublicLayout>
    </>
  );
};

export default Financial;
